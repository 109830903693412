import React from 'react';
import { ServicesItemWrapper, Wrapper, StyledContainer } from './styles';
import MainSection from '../../components/Layout';
import { Box, Grid } from '@mui/material';
import ServicesItem from '../../components/serviceItems';
import { useLocation } from 'react-router-dom';
import Heading from '../../components/Heading';
import client from '../../client';
import imageUrlBuilder from '@sanity/image-url';
import { StyledCircularProgress, StyledProgressContainer } from '../services/styles';
import { Description } from './styles';

const ServicePage = () => {
  const builder = imageUrlBuilder(client);
  const { state } = useLocation();
  const { title, content } = state;
  const { companyDetails, categories } = content[0];

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <>
      {companyDetails ? (
        <>
          <MainSection serviceName={title} />
          <Wrapper>
            <StyledContainer container>
              <Grid sx={{ paddingRight: '20px' }} item xs={12} sm={12} md={8}>
                {companyDetails.map((detail) => {
                  const {
                    heading,
                    subDescription,
                    description,
                    secondDescription,
                    thirdDescription,
                    fourthDescription,
                    contentImage,
                  } = detail;

                  return (
                    <Box>
                      {heading && subDescription && <Heading title={heading} subTitle={subDescription} />}
                      {description && (
                        <Description sx={{ paddingBottom: 1 }} variant="body1">
                          {description}
                        </Description>
                      )}
                      {secondDescription && (
                        <Description sx={{ paddingTop: 0, paddingBottom: 4 }} variant="body1">
                          {secondDescription}
                        </Description>
                      )}
                      <Box>
                        {contentImage && <img src={urlFor(contentImage)} alt="service" />}
                        {thirdDescription && (
                          <Description sx={{ paddingTop: 4, paddingBottom: 0 }}>{thirdDescription}</Description>
                        )}
                        {fourthDescription && <Description sx={{ paddingTop: 1 }}>{fourthDescription}</Description>}
                      </Box>
                    </Box>
                  );
                })}
              </Grid>
              {categories && (
                <Grid item xs={12} sm={12} md={4}>
                  <ServicesItemWrapper>
                    <Description variant="body1">Our Services</Description>
                    {categories?.map((item, index) => {
                      return <ServicesItem key={index} serviceName={item} />;
                    })}
                  </ServicesItemWrapper>
                </Grid>
              )}
            </StyledContainer>
          </Wrapper>
        </>
      ) : (
        <StyledProgressContainer>
          <StyledCircularProgress />
        </StyledProgressContainer>
      )}
    </>
  );
};

export default ServicePage;
