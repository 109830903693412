import styled from '@emotion/styled';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Section } from '../../components/common';

export const StyledSection = styled(Section)({
  position: 'relative',
});

export const GuidBox = styled(Box)(({ image }) => {
  return {
    width: '100%',
    height: '20rem',
    display: 'flex',
    alignItems: 'flex-end',
    backgroundImage: image ? `url(${image})` : null,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  };
});

export const GuidContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.white?.main,
  margin: theme.spacing(3),
  padding: theme.spacing(3),
  h4: {
    marginBottom: theme.spacing(1),
    fontSize: '1.5rem',
    fontWeight: '700',
  },
  [theme.breakpoints.down('md')]: {
    h4: {
      fontSize: '1rem',
    },
    p: {
      fontSize: '.8rem',
    },
  },
}));

export const StyledProgressContainer = styled(Box)({
  height: '300px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  width: ' 100%',
});

export const StyledCircularProgress = styled(CircularProgress)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledHeadingWrapper = styled(Box)(({ theme }) => ({
  padding: '50px',
  textAlign: 'center',
  width: '70%',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingTop: '30px',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    h5: {
      fontSize: '1rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '0.9rem',
      width: '95%',
    },
  },
}));

export const StyledServicesTypo = styled(Box)(({ theme }) => ({
  color: 'gray',
  fontWeight: 500,
}));

export const StyledServicesMain = styled(Typography)({
  fontWeight: 800,
  marginTop: '30px',
  width: '95%',
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const StyledServicesDesc = styled(Typography)({
  fontWeight: 400,
  marginTop: '30px',
  width: '85%',
  marginLeft: 'auto',
  marginRight: 'auto',
});
