import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ClientReviewsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
}));

export const CommentContainer = styled(Box)(({ theme }) => ({
  width: '70%',
  color: theme.palette.secondary.light,
  p: { padding: theme.spacing(0.8) },
  [theme?.breakpoints.down('md')]: {
    p: { fontSize: '0.75rem', padding: 0 },
  },
  [theme?.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const TestimonialContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(0.6),
  display: 'flex',
  alignItems: 'center',
  width: '70%',
  justifyContent: 'space-between',
}));
