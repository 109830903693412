import React, { useEffect, useState } from 'react';
import Carousel from '../../components/Slider';
import ProfileCard from '../../components/ProfileCard';
import ServicesCard from '../../components/ServicesCard';
import TestimonialsCard from '../../components/testimonialsCard';
import { Box, Container, Grid } from '@mui/material';
import Section from '../../components/Section';
import Banner from '../banner';
import {
  SponsorsWrapper,
  SlideWrapper,
  GridWrapper,
  StyledGrid,
  HeadingWrapper,
  StyledImage,
  StyledGridContainer,
} from './styles';
import Heading from '../../components/MainHeading';
import Sponsor from '../../components/Sponsor';
import NewsLetter from '../../components/NewsLetter';
import sanityClient from './../../client';
import OnboardingCard from '../../components/OnboardingCard';
import ClientReviews from '../../components/ClientReviews';
import { StyledProgressContainer } from '../services/styles';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { StyledSecondTypo } from '../../components/OnboardingCard/styles';
import imageUrlBuilder from '@sanity/image-url';

import client from '../../client';

const HomePage = () => {
  const [whyChooseUs, setWhyChooseUs] = useState({});
  const [servicesList, setServicesList] = useState({});
  const [hero, setHero] = useState({});
  const [team, setTeam] = useState({});
  const [clientReview, setClientReview] = useState({});
  const [clients, setClients] = useState({});
  const [testimonials, setTestimonials] = useState({});
  const [onBoarding, setOnBoarding] = useState({});

  const specialOrderTitles = ['Xact Financials', 'Comverse', 'Archified', 'Sapienx Iris', 'ReachFlair', 'NorthSpex'];

  const builder = imageUrlBuilder(client);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "servicesList"][0]`)
      .then((data) => setServicesList(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "onBoarding"][0]`)
      .then((data) => setOnBoarding(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "hero"][0]`)
      .then((data) => setHero(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "team"][0]`)
      .then((data) => setTeam(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "whyChooseUs"][0]`)
      .then((data) => setWhyChooseUs(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "clients"][0]`)
      .then((data) => setClients(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "testimonials"][0]`)
      .then((data) => setTestimonials(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "clientReview"][0]`)
      .then((data) => setClientReview(data))
      .catch(console.error);
  }, []);

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <Box>
      <Container>
        <Banner hero={hero} />
      </Container>
      <Section id="target-element" variant="primaryDark">
        <SlideWrapper>
          <Container>
            <HeadingWrapper>
              <Heading marginbottomzero={'true'} title={clientReview?.heading} subTitle={clientReview?.subHeading} />
            </HeadingWrapper>
            <Carousel dots>
              {clientReview?.clientReviews?.length ? (
                clientReview?.clientReviews?.map((item) => (
                  <ClientReviews
                    key={item._id}
                    name={item.reviewerName}
                    authorDetails={item.reviewerDesignation}
                    comment={item.review}
                    rating={item.upworkRating}
                  />
                ))
              ) : (
                <StyledProgressContainer sx={{ display: 'flex !important' }}>
                  There are no Reviews! &nbsp; <SentimentVeryDissatisfiedIcon />
                </StyledProgressContainer>
              )}
            </Carousel>
          </Container>
        </SlideWrapper>
      </Section>
      <Section>
        <Container>
          <HeadingWrapper>
            <Heading title={servicesList?.heading} subTitle={servicesList?.subHeading} dark />
          </HeadingWrapper>
          <Grid container spacing={[5]} justifyContent={'center'}>
            {servicesList?.services?.length ? (
              servicesList?.services
                ?.sort((a, b) => {
                  const indexA = specialOrderTitles.indexOf(a.title);
                  const indexB = specialOrderTitles.indexOf(b.title);

                  if (indexA !== -1 || indexB !== -1) {
                    if (indexA === -1) {
                      return 1;
                    }
                    if (indexB === -1) {
                      return -1;
                    }
                    return indexA - indexB;
                  }

                  const titleA = a.title.toLowerCase();
                  const titleB = b.title.toLowerCase();
                  if (titleA < titleB) {
                    return -1;
                  }
                  if (titleA > titleB) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((item, key) => (
                  <Grid item xs={12} sm={6} md={4} key={`service-${key}`}>
                    <ServicesCard item={item} />
                  </Grid>
                ))
            ) : (
              <StyledProgressContainer>
                There are no Services! &nbsp; <SentimentVeryDissatisfiedIcon />
              </StyledProgressContainer>
            )}
          </Grid>
        </Container>
      </Section>
      <Section variant="primaryDark">
        <Container>
          <HeadingWrapper>
            <Heading title={onBoarding?.heading} subTitle={onBoarding?.subHeading} />
          </HeadingWrapper>
          <Grid container gap={4} justifyContent="center">
            {onBoarding?.steps?.length ? (
              onBoarding?.steps?.map((card) => {
                return (
                  <StyledGrid key={card.id} item sm={5} md={2.7} className="StyledGrid">
                    <OnboardingCard key={card.id} title={card.title} number={card.number} variant={'onBoarding'} />
                  </StyledGrid>
                );
              })
            ) : (
              <StyledProgressContainer>
                There is No Data! &nbsp; <SentimentVeryDissatisfiedIcon />
              </StyledProgressContainer>
            )}
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <HeadingWrapper>
            <Heading className="sponsor_heading" title={whyChooseUs?.heading} dark />
          </HeadingWrapper>
          <Grid container gap={4}>
            <StyledGridContainer item xs={12} md={12}>
              {whyChooseUs?.image && <StyledImage src={urlFor(whyChooseUs?.image)} alt="why us" />}
              <StyledSecondTypo>{whyChooseUs?.description}</StyledSecondTypo>
              <StyledSecondTypo>{whyChooseUs?.secondDescription}</StyledSecondTypo>
            </StyledGridContainer>
            <Grid container gap={4} justifyContent="center">
              {whyChooseUs?.additionalInfo?.map((card) => {
                return (
                  <StyledGrid key={card._key} item sm={5} md={2.7} className="StyledGrid">
                    <OnboardingCard key={card._id} title={card.subDescription} number={card.subHeading} variant={''} />
                  </StyledGrid>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section variant="primaryDark">
        <SlideWrapper>
          <Container>
            <HeadingWrapper>
              <Heading marginbottomzero={'true'} title={testimonials?.heading} subTitle={testimonials?.subHeading} />
            </HeadingWrapper>
            <Carousel dots>
              {testimonials?.testimonialsList?.length ? (
                testimonials?.testimonialsList?.map((item, id) => <TestimonialsCard key={id} item={item} />)
              ) : (
                <StyledProgressContainer sx={{ display: 'flex !important' }}>
                  There are no Testimonials! &nbsp; <SentimentVeryDissatisfiedIcon />
                </StyledProgressContainer>
              )}
            </Carousel>
          </Container>
        </SlideWrapper>
      </Section>
      <Section>
        <Container>
          <HeadingWrapper>
            <Heading title={team?.heading} subTitle={team?.subHeading} dark />
          </HeadingWrapper>
          <Grid container spacing={[4]}>
            {team?.teamList?.map((item, key) => {
              return (
                <Grid key={`user-${key}`} item xs={12} sm={6} md={3}>
                  <ProfileCard item={item} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Section>
      <Section variant="primaryDark">
        <SponsorsWrapper>
          <Container>
            <GridWrapper container spacing={5}>
              <Grid item xs={12} md={12}>
                <HeadingWrapper>
                  <Heading className="sponsor_heading" title={clients?.heading} subTitle={clients?.subHeading} />
                </HeadingWrapper>
              </Grid>
              <Grid container item xs={12} md={12} spacing={4}>
                {clients?.clientItems?.length ? (
                  clients?.clientItems?.map((sponsor, id) => (
                    <Grid key={id} item xs={12} sm={6} md={3}>
                      <Sponsor url={sponsor.url} image={sponsor.image} label={sponsor.label} />
                    </Grid>
                  ))
                ) : (
                  <StyledProgressContainer style={{ color: 'white' }}>
                    There are no Clients! &nbsp; <SentimentVeryDissatisfiedIcon />
                  </StyledProgressContainer>
                )}
              </Grid>
            </GridWrapper>
          </Container>
        </SponsorsWrapper>
      </Section>
      <Section>
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6}>
              <HeadingWrapper>
                <Heading alignleft="true" marginbottomzero={'true'} title={'join us'} subTitle={'news letter.'} dark />
              </HeadingWrapper>
            </Grid>
            <Grid container item xs={12} md={6}>
              <NewsLetter />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Box>
  );
};

export default React.memo(HomePage);
