import React from 'react';
import Facebook from '../Svgs/Facebook';
import LinkedIn from '../Svgs/Linkedin';
import Twitter from '../Svgs/Twitter';
import Mail from '../Svgs/Mail';
import {
  Image,
  ImageWrapper,
  ProfileWrapper,
  UserName,
  UserRole,
  UserDetails,
  DetailsWrapper,
  ProfileContentWrapper,
  IconsWrapper,
  IconBorder,
  IconsContentWrapper,
} from './styles';

import { useNavigate } from 'react-router-dom';
import client from '../../client';
import imageUrlBuilder from '@sanity/image-url';

const Profile = ({ item }) => {
  const { _key, image, title, bio, facebookLink, linkedInLink, email, abbreviation, twitterLink } = item;

  const builder = imageUrlBuilder(client);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/home/contact/${_key}`);
  };

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <ProfileWrapper>
      {image && (
        <ImageWrapper onClick={handleClick}>
          <Image src={urlFor(image)} alt={title} />
        </ImageWrapper>
      )}
      <ProfileContentWrapper onClick={handleClick}>
        {title && <UserName>{title}</UserName>}
        {abbreviation && <UserRole>{abbreviation}</UserRole>}
        {bio && (
          <DetailsWrapper>
            <UserDetails>{bio}</UserDetails>
          </DetailsWrapper>
        )}
      </ProfileContentWrapper>
      <IconsContentWrapper>
        <IconsWrapper>
          {facebookLink && (
            <IconBorder to={facebookLink} target="_blank">
              <Facebook className="social-icon" color="#fff" />
            </IconBorder>
          )}
          {twitterLink && (
            <IconBorder to={twitterLink} target="_blank">
              <Twitter className="social-icon" color="#fff" />
            </IconBorder>
          )}
          {linkedInLink && (
            <IconBorder to={linkedInLink} target="_blank">
              <LinkedIn className="social-icon" color="#fff" />
            </IconBorder>
          )}
          {email && (
            <IconBorder to={email} target="_blank" onClick={() => window.open(`mailto:${email}`)}>
              <Mail className="socialIcon" color="#fff" />
            </IconBorder>
          )}
        </IconsWrapper>
      </IconsContentWrapper>
    </ProfileWrapper>
  );
};

export default Profile;
