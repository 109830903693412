import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Wrapper = styled(Box)(
  ({
    theme: {
      palette: { primaryDark, white },
    },
  }) => ({
    background: primaryDark?.main,
    color: white?.main,
    width: '100%',
    height: 'auto',
    borderRadius: '4px',
  })
);

export const Img = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '250px',
  '& img': {
    borderRadius: '4px',
    width: '100%',
    objectFit: 'cover',
    height: '100%',
  },
}));
export const TextWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2.8),
}));
export const Information = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2.8),
}));
export const By = styled('h4')(({ theme }) => ({
  textTransform: 'uppercase',
  margin: '0',
  fontWeight: '400',
  fontSize: '1rem',
  marginRight: theme.spacing(3),
  color: theme.palette.white?.main,
}));
export const Category = styled('a')(
  ({
    theme: {
      palette: { primary },
    },
  }) => ({
    textDecoration: 'none',
    fontSize: '1rem',
    color: primary?.main,
  })
);
export const Title = styled('h4')(({ theme }) => ({
  textTransform: 'uppercase',
  margin: '0',
  fontWeight: '400',
  fontSize: '1rem',
  marginRight: theme.spacing(3),
  color: theme.palette.white?.main,
}));
export const Description = styled('p')(({ theme }) => ({
  fontWeight: '200',
  fontSize: '1.4rem',
  color: theme.palette.secondary.light,
  letterSpacing: '1.3px',
  marginBottom: theme.spacing(1),
}));
export const BadgeWrapper = styled(Box)({});
export const Badge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  right: '16px',
  padding: theme.spacing(0.8, 1),
  background: theme.palette.white?.main,
  fontSize: '.8125rem',
  fontWeight: '500',
  textAlign: 'center',
  zIndex: '2',
  boxShadow: '0px 0px 0px 3px rgb(255,255,255,.2)',
}));
export const Date = styled('h3')(({ theme }) => ({
  margin: '0',
  marginBottom: theme.spacing(0.4),
  color: theme.palette.black.light,
}));
export const Month = styled('p')(({ theme }) => ({
  textTransform: 'uppercase',
  margin: '0',
  letterSpacing: '1.1px',
  color: theme.palette.black.light,
}));

export const ButtonWrapper = styled(Link)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textDecoration: 'none',
  '& button': {
    padding: 0,
  },
}));

export default Wrapper;
