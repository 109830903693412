import React from 'react';
import { ServicesItemWrapper, Item } from './styles';
import MainSection from '../../components/Layout';
import { Description } from '../../components/common';
import { Box, Container, Grid } from '@mui/material';
import ServicesItem from '../../components/serviceItems';
import ProfileCard from '../../components/ProfileCard';
import { useLocation } from 'react-router-dom';
import Section from '../../components/Section';

const TeamPage = () => {
  const { state } = useLocation();
  return (
    <Box>
      <MainSection teamName={state.teamName} />
      <Container>
        <Section>
          <Grid container spacing={4}>
            <Grid container item xs={12} sm={4} md={8} spacing={4}>
              {state.teamData.map((item, key) => (
                <Grid key={key} item xs={12} sm={8} md={6}>
                  <Item key={`data ${key}`}>
                    <ProfileCard
                      userImg={item.img}
                      userName={item.name}
                      userRole={item.role}
                      userDetails={item.userDetails}
                    />
                  </Item>
                </Grid>
              ))}
            </Grid>
            <Grid container item xs={12} sm={4} md={4}>
              <ServicesItemWrapper>
                <Description>Our Teams</Description>
                {state.data.map((item, key) => (
                  <div key={`data-${key}`}>
                    <ServicesItem teamName={item.title} teamData={item.data} data={state.data} />
                  </div>
                ))}
              </ServicesItemWrapper>
            </Grid>
          </Grid>
        </Section>
      </Container>
    </Box>
  );
};

export default TeamPage;
