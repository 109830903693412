import {
  NotFoundContent,
  NotFoundHeader,
  NotFoundLink,
  NotFoundSubheader,
  NotFoundText,
  NotFoundWrapper,
} from './NotFound.styles';

export const NotFound = () => (
  <NotFoundWrapper>
    <NotFoundContent>
      <NotFoundHeader>404</NotFoundHeader>
      <NotFoundSubheader>Page not found</NotFoundSubheader>
      <NotFoundText>
        The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
      </NotFoundText>
      <NotFoundLink to={'/'}>Go to Homepage</NotFoundLink>
    </NotFoundContent>
  </NotFoundWrapper>
);
