import { Box, Container } from '@mui/material';
import React from 'react';
import { MissionDesc, StyledMainHeading, StyledTitle } from './styles';

const FAQs = () => {
  return (
    <Container>
      <Box sx={{ marginTop: '70px' }}>
        <StyledMainHeading>FAQs</StyledMainHeading>
      </Box>
      <Box sx={{ marginTop: '30px' }}>
        <StyledTitle>What is Business Process Outsourcing (BPO)?</StyledTitle>
      </Box>
      <Box>
        <MissionDesc>
          Business Process Outsourcing (BPO) refers to the practice of contracting specific business operations or
          processes to a third-party service provider. This enables companies to focus on their core competencies while
          delegating non-core tasks to specialized external agencies.
        </MissionDesc>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <StyledTitle>What services does your BPO agency offer?</StyledTitle>
      </Box>
      <Box>
        <MissionDesc>
          We offer various BPO services, including digital marketing, e-commerce management, 2D & 3D design, content
          moderation, finance and accounting, human resources outsourcing, and more. Our services are tailored to meet
          the diverse needs of businesses across various industries.
        </MissionDesc>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <StyledTitle>How can your services benefit my business?</StyledTitle>
      </Box>
      <Box>
        <MissionDesc>
          Outsourcing business processes to a specialized agency like ours can result in cost savings, increased
          operational efficiency, access to specialized skills, scalability, improved focus on core business activities,
          and enhanced service quality.
        </MissionDesc>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <StyledTitle>Is my company's data safe with your agency?</StyledTitle>
      </Box>
      <Box>
        <MissionDesc>
          Absolutely. We take data security and confidentiality very seriously. Our systems and processes adhere to
          strict security protocols and industry standards to ensure the protection of your sensitive information.
        </MissionDesc>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <StyledTitle>How flexible are your BPO service plans?</StyledTitle>
      </Box>
      <Box>
        <MissionDesc>
          We understand that businesses have unique needs. Hence, our service plans are highly flexible and scalable. We
          offer customizable solutions to suit your specific requirements, whether you're a small startup or a large
          enterprise.
        </MissionDesc>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <StyledTitle>How do I get started with your services?</StyledTitle>
      </Box>
      <Box>
        <MissionDesc>
          Getting started is simple! Reach out to our team through our website or contact details provided. We'll
          schedule a consultation to understand your needs and tailor a solution that best fits your business
          requirements.
        </MissionDesc>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <StyledTitle>Do you offer trial periods for your services?</StyledTitle>
      </Box>
      <Box sx={{ marginBottom: '100px' }}>
        <MissionDesc>
          Yes, we offer trial periods for selected services. It's an opportunity for you to experience the quality of
          our services firsthand before making a commitment. Contact us to learn more about our trial options.
        </MissionDesc>
      </Box>
    </Container>
  );
};

export default FAQs;
