import styled from '@emotion/styled';
import { Paper, Box, Grid } from '@mui/material';

export const TitleBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));
export const Item = styled(Paper)({
  boxShadow: 'none',
  borderBottomLeftRadius: 'none',
  borderRadius: 'unset',
});
export const Filters = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '60px',
});
export const SearchWrapper = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
});
export const SearchBar = styled('div')({
  display: 'flex',
  width: '200px',
});

export const DepartmentHeading = styled('h2')(({ theme }) => ({
  padding: theme.spacing(1, 0),
  margin: '0',
}));
export const ProfileWrapper = styled('h2')(({ theme }) => ({
  marginBottom: theme.spacing(7.5),
}));

export const ViewAll = styled('p')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '1.2rem',
  cursor: 'pointer',
  letterSpacing: '2px',
  color: theme.palette.secondary.light,
  margin: 0,
  '& svg': {
    marginLeft: theme.spacing(1.6),
  },
}));

export const LoadMoreBtn = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(5),
}));
