import { styled } from '@mui/system';
import { Box } from '@mui/material';

const Wrapper = styled(Box)({});

export const MissionHeading = styled('h4')(
  ({
    theme: {
      palette: { black },
      breakpoints,
    },
  }) => ({
    margin: '0',
    fontSize: '2rem',
    color: black?.main,
    fontWeight: '600',
    letterSpacing: '2px',
    textTransform: 'capitalize',
    [breakpoints?.down('md')]: {
      fontSize: '1.5rem',
      textAlign: 'center',
    },
  })
);

export const SubHeading = styled('p')(
  ({
    theme: {
      palette: { primary },
      breakpoints,
    },
  }) => ({
    margin: '0',
    fontSize: '1.5rem',
    color: primary?.main,
    fontWeight: '300',
    letterSpacing: '1px',
    [breakpoints?.down('md')]: {
      fontSize: '1.2rem',
      textAlign: 'center',
    },
  })
);

export default Wrapper;
