import styled from '@emotion/styled';
import { Box, Paper } from '@mui/material';

export const Wrapper = styled(Box)({
  display: 'flex',
});
export const ServicesItemWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.lightnessWhite.main,
  padding: theme.spacing(4),
  height: 'fit-content',
}));
export const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  display: 'flex',
  marginBottom: theme.spacing(3),
  borderBottomLeftRadius: 'none',
  borderTopLeftRadius: 'none',
}));
