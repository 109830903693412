import { ServicesWrapper, UserName } from './styles';

const ServicesItem = ({ serviceName, teamName }) => {
  // const navigate = useNavigate();
  // let name = serviceName ? 'services' : 'team';
  // let stateData = serviceName
  //   ? {
  //       serviceName: serviceName,
  //     }
  //   : { teamName: teamName, teamData: teamData, data: data };

  // const handleClick = () => {
  //   navigate(`/${name}/${serviceName ? serviceName : teamName}`, {
  //     state: stateData,
  //   });
  // };
  return (
    <ServicesWrapper>
      <UserName>{serviceName ? serviceName : teamName}</UserName>
      {/* <StyledForwardIcon onClick={handleClick} /> */}
    </ServicesWrapper>
  );
};

export default ServicesItem;
