import React from 'react';
import GitLab from '../Svgs/GitLab';
import BookOpen from '../Svgs/BookOpen';
import Airplay from '../Svgs/Airplay';
import Wrapper, { AchievementTotall, SubTitle } from './styles';

const Achivement = ({ title, category, index, textLight }) => {
  return (
    <Wrapper>
      {index === 0 ? <Airplay /> : index === 1 ? <BookOpen /> : index === 2 ? <GitLab /> : null}
      <AchievementTotall textLight={textLight}>{title}</AchievementTotall>
      <SubTitle textLight={textLight}>{category}</SubTitle>
    </Wrapper>
  );
};

export default Achivement;
