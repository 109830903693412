import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import {
  UserTitle,
  StyledBox,
  UserContent,
  UserImage,
  UserData,
  AboutDesc,
  MainBox,
  DescriptionBox,
  CustomizeAccordion,
  ProgressContent,
  AccordionTitle,
  AccordionContent,
  UserRole,
  AchivementWrapper,
  SocialIcons,
  IconBorder,
  SubHeading,
  StyledSummary,
  Heading,
} from './styles';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress, LinearProgress } from '../../components/ProgressBar';
import TimeLine from '../../components/TimeLine';
import Achivement from '../../components/Achivement';
import Section from '../../components/Section';
// feather icons
import FacebookFeather from '../../components/Svgs/Facebook';
import LinkedinFeather from '../../components/Svgs/Linkedin';
import TwitterFeather from '../../components/Svgs/Twitter';
import MailFeather from '../../components/Svgs/Mail';
import client from '../../client';
import sanityClient from './../../client';
import imageUrlBuilder from '@sanity/image-url';

const PersonalProfile = () => {
  const params = useParams();
  const _key = params.name;

  const [progressValue, setProgressValue] = useState();

  const [teamMember, setTeamMember] = useState({});
  const {
    about,
    abbreviation,
    completedJobs,
    experience,
    facebookLink,
    image,
    introduction,
    linkedInLink,
    role,
    skills,
    title,
    twitterLink,
  } = teamMember;

  const builder = imageUrlBuilder(client);

  const experties = experience?.[0]?.experties;
  const knowMore = experience?.[0]?.knowMore;

  const aboutOurTeam = introduction?.[0]?.aboutOurTeam;
  const teamBio = introduction?.[0]?.teamBio;

  const aboutTeam = about?.[0]?.aboutTeam;
  const otherExpertise = about?.[0]?.otherExpertise;

  const skill = skills?.[0]?.skill;
  const skillsDisplay = skills?.[0]?.skillsDisplay;

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "team"][0] {
          teamList[_key == $itemKey]
        }`,
        { itemKey: _key }
      )
      .then((data) => {
        const teamListItem = data?.teamList[0];
        return setTeamMember(teamListItem);
      })
      .catch(console.error);
  }, [_key]);

  useEffect(() => {
    if (skill?.length) {
      const sum = skill?.reduce((accumulator, object) => {
        return accumulator + object.skillLevel;
      }, 0);
      const overAllPercentage = sum / skill?.length;
      setProgressValue(overAllPercentage);
    }
  }, [skill]);

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <MainBox>
      <StyledBox>
        <Container>
          <Grid container spacing={[4]} alignItems="center">
            <Grid item xs={12} sm={12} md={8}>
              <UserContent>
                <UserImage src={image && urlFor(image)} alt="user" />
                <UserData>
                  <UserTitle>{title}</UserTitle>
                  <UserRole color="#ffffff" fontSize="36px">
                    {role}
                  </UserRole>
                </UserData>
              </UserContent>
            </Grid>
            <Grid item xs={12} sm={12} md={4}></Grid>
          </Grid>
        </Container>
        <SocialIcons>
          <IconBorder to={facebookLink} target="_blank">
            <FacebookFeather className="social-icon" color="#fff" />
          </IconBorder>
          <IconBorder to={linkedInLink} target="_blank">
            <LinkedinFeather className="social-icon" color="#fff" />
          </IconBorder>
          <IconBorder to={twitterLink} target="_blank">
            <TwitterFeather className="social-icon" color="#fff" />
          </IconBorder>
          <IconBorder
            to="#"
            onClick={() => window.open(`mailto:calibreoninternationalofficial@gmail.com`)}
            target="_blank"
          >
            <MailFeather className="socialIcon" color="#fff" />
          </IconBorder>
        </SocialIcons>
      </StyledBox>
      <DescriptionBox>
        <Container>
          <AboutDesc>
            <Heading>{abbreviation}</Heading>
            <SubHeading>{aboutOurTeam}</SubHeading>
            {teamBio}
          </AboutDesc>
        </Container>
      </DescriptionBox>
      {skill && (
        <Section>
          <Container>
            <SubHeading>{skillsDisplay}</SubHeading>
            <ProgressContent>
              <Grid container alignItems="center" spacing={8}>
                <Grid item xs={12} sm={12} md={7} lg={6} xl={8}>
                  {skill?.map((item, id) => (
                    <LinearProgress
                      className={id === 0 ? 'mt-0' : 'mt-5'}
                      key={id}
                      label={item?.technology}
                      percentage={item?.skillLevel}
                      thickness={3}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={6} xl={4}>
                  <CircularProgress
                    title="Over All Percentage"
                    percentage={progressValue?.toFixed(2)}
                    size={200}
                    thickness={5}
                  />
                </Grid>
              </Grid>
            </ProgressContent>
          </Container>
        </Section>
      )}
      <Section variant="primaryDark">
        <Container>
          <Grid container>
            {completedJobs?.map((item, id) => (
              <Grid key={id} item xs={12} sm={6} md={3}>
                <AchivementWrapper>
                  <Achivement key={id} title={item.jobs} category={item.technology} size="size" textLight />
                </AchivementWrapper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>
      {experties?.length && (
        <Section>
          <Container>
            <SubHeading>{knowMore}</SubHeading>
            <TimeLine item={experties} />
          </Container>
        </Section>
      )}
      {otherExpertise && (
        <Section>
          <Container>
            <SubHeading>{aboutTeam}</SubHeading>
            {otherExpertise?.map((experty, index) => (
              <CustomizeAccordion key={index}>
                <StyledSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <AccordionTitle>{experty.heading}</AccordionTitle>
                </StyledSummary>
                <AccordionContent>{experty.content}</AccordionContent>
              </CustomizeAccordion>
            ))}
          </Container>
        </Section>
      )}
    </MainBox>
  );
};

export default PersonalProfile;
