import { Box } from '@mui/material';
import { styled } from '@mui/system';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';

export const StyledTimeLine = styled(Box)({
  marginTop: '2rem',
});

export const Title = styled('h6')({
  fontSize: '1.5rem',
  margin: 0,
  fontWeight: '600',
  letterSpacing: '1.4px',
});

export const StyledContent = styled(TimelineContent)({
  paddingTop: 0,
});
export const Name = styled('h6')(({ theme }) => ({
  fontSize: '1.3rem',
  margin: 0,
  fontWeight: '500',
  paddingBottom: theme.spacing(1),
}));

export const Content = styled('p')({
  margin: 0,
  padding: 0,
  fontWeight: '400',
  fontSize: '1rem',
  letterSpacing: '1px',
});

export const StyledSeparator = styled(TimelineSeparator)({
  '& .MuiTimelineDot-root': {
    boxShadow: 'none',
  },
});

export const Date = styled('p')(
  ({
    theme: {
      palette: { primary },
      spacing,
    },
  }) => ({
    margin: 0,
    padding: spacing(1.3, 0),
    color: primary?.main,
    fontSize: '1.5rem',
    fontWeight: '600',
    letterSpacing: '1px',
  })
);
