import styled from '@emotion/styled';
import { Box } from '@mui/material';
import Link from '@mui/material/Link';

export const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  background: theme.palette.white?.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '160px',
  borderRadius: '4px',
  overflow: 'hidden',
  '&:hover .sponsor_url': {
    width: '100%',
    padding: `${theme.spacing(0, 2)}`,
  },
  '&:hover .sponsor_img': {
    opacity: '0',
    transform: 'scale(0.2)',
  },
}));

export const Label = styled(Link)(({ theme }) => ({
  position: 'absolute',
  textDecoration: 'none',
  height: '1.5rem',
  textOverflow: 'ellipsis',
  boxSizing: 'border-box',
  width: '0',
  color: theme.palette.black.main,
  fontSize: '0.875rem',
  letterSpacing: '0.0625rem',
  overflow: 'hidden',
  textAlign: 'center',
  transition: '0.5s all ease',
  zIndex: '1',
  padding: '0',
}));

export const Img = styled('img')({
  maxWidth: '70%',
  zIndex: 0,
  opacity: '1',
  transition: '0.4s all ease-in-out',
});
