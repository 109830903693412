import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const SponsorsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme?.breakpoints.down('md')]: {
    '.sponsor_heading': {
      alignItems: 'center',
    },
  },
}));

export const SlideWrapper = styled(Box)(({ theme }) => ({
  '.slick-dots': {
    position: 'unset',
  },
  '.slick-dots li': {
    width: '0.4375rem',
    height: '0.4375rem',
    borderRadius: '50%',
    border: `0.0625rem solid ${theme.palette.white.main}`,
    marginTop: theme.spacing(2.8),
    '&.slick-active': {
      background: theme.palette.white.main,
    },
  },
  '.slick-dots li button': {
    '&.slick-active': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.white.main,
    },
    '&::before': {
      content: 'none',
    },
  },
  textAlign: 'center',
  color: theme.palette.white.main,
  '.slick-next': {
    padding: theme.spacing(2),
    right: '1.25rem',
    top: '32%',
    fontSize: '1rem',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    transition: '0.3s ease-in',
    border: `0.0625rem solid ${theme.palette.secondary.main}`,
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      fontSize: '.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  '.slick-prev': {
    padding: theme.spacing(2),
    left: '1.25rem',
    top: '32%',
    color: theme.palette.secondary.main,
    border: `0.0625rem solid ${theme.palette.secondary.main}`,
    fontSize: '1rem',
    borderRadius: '50%',
    zIndex: ' 1',
    transition: '0.2s ease',
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      fontSize: '.8rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    svg: {
      display: 'none !important',
    },
  },
}));

export const GridWrapper = styled(Grid)({
  flexDirection: 'column',
  alignItems: 'center',
});
export const HeadingWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(13),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(6),
  },
}));
export const StyledGrid = styled(Grid)({
  width: '100%',
});
export const ServiceContiner = styled(Grid)(({ theme }) => ({
  marginTop: `${theme.spacing(14)} !important`,
}));

export const StyledImage = styled('img')(({ theme }) => ({
  marginBottom: `${theme.spacing(4)}`,
  height: '400px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'unset',
  },
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const Paragraph = styled('p')(
  ({
    theme: {
      palette: { black },
    },
  }) => ({
    boxSizing: 'border-box',
    color: black.light,
    fontSize: '1rem',
    fontWeight: '300',
    lineHeight: '2',
    padding: '0',
    letterSpacing: '1.3px',
    textAlign: 'center',
  })
);
