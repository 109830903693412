import styled from '@emotion/styled';
import { Box } from '@mui/material';

const Wrapper = styled(Box)(({ alignleft, marginbottomzero, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: alignleft ? 'start' : 'center',
  height: '100%',
  marginBottom: marginbottomzero ? '0' : `${theme.spacing(6)}'45px'`,
  [theme?.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

export const SubTitle = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: '300',
  fontSize: '1.4rem',
  textTransform: 'uppercase',
  letterSpacing: '0.7rem',
  display: 'inline-block',
  [theme?.breakpoints.down('md')]: {
    fontSize: '0.8rem',
    textAlign: 'center',
  },
}));

export const Title = styled('h3')(({ dark, theme }) => ({
  margin: '0',
  textTransform: 'uppercase',
  fontSize: '4rem',
  letterSpacing: '6px',
  fontWeight: '700',
  color: dark ? theme.palette.black.main : theme.palette.white.main,
  [theme?.breakpoints.down('md')]: {
    fontSize: '1.6rem',
    textAlign: 'center',
  },
}));

export default Wrapper;
