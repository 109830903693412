import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
const Wrapper = styled(Box)(
  ({
    theme: {
      palette: { primaryDark },
    },
  }) => ({
    background: primaryDark?.main,
  })
);
export const StyledGrid = styled(Grid)({
  marginTop: 0,
});

export const LinksWrapper = styled(Box)(
  ({
    theme: {
      palette: { lightGray },
    },
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '.875rem',
    textDecoration: 'none',
    color: lightGray?.main,
  })
);

export const MenuLinks = styled('a')(({ theme }) => ({
  width: 'fit-content',
  fontSize: '1rem',
  lineHeight: '2rem',
  textDecoration: 'none',
  color: theme.palette.white.main,
  marginBottom: theme.spacing(1),
  letterSpacing: '1px',
  fontWeight: '200',
  cursor: 'pointer',
}));

export const Item = styled(Box)(({ theme }) => ({
  background: 'transparent',
}));

export const LogoWrapper = styled(Box)({
  width: '160px',
  img: {
    width: '100%',
    height: '100%',
  },
});
export const DepartmentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  a: {
    textDecoration: 'none',
    color: theme.palette.secondary.light,
    fontWeight: '200',
    fontSize: '.967rem',
    letterSpacing: '1px',
    marginBottom: theme.spacing(1),
  },
}));

export const AddressWrapper = styled('div')(
  ({
    theme: {
      palette: { secondary },
    },
  }) => ({
    fontSize: '.967rem',
    textDecoration: 'none',
    color: secondary?.light,
    margin: '0',
  })
);

export const Address = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontWeight: '200',
  letterSpacing: '1px',
  margin: '0',
  wordWrap: 'break-word',
  textDecoration: 'none',
}));
export const Contact = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.light,
  letterSpacing: '1px',
  margin: '0',
  marginTop: theme.spacing(4),
  fontWeight: '200',
  wordWrap: 'break-word',
}));

export const DesignedBy = styled('p')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '.967rem',
  lineHeight: '2rem',
  textDecoration: 'none',
  color: theme.palette.white.main,
  padding: theme.spacing(3),
  margin: '0',
  marginTop: theme.spacing(3),
  letterSpacing: '1px',
  background: '#0097ff1c',
}));

export const SocialIcon = styled(Box)(({ theme }) => ({
  height: '100px',
  display: 'flex',
  alignItems: 'end',
  // [theme?.breakpoints.down('md')]: {
  //   justifyContent: 'center',
  // },
}));

export const BorderRadius = styled(Link)(({ theme }) => ({
  height: '22px',
  width: '22px',
  border: `1px solid ${theme.palette.secondary.dark}`,
  display: 'flex',
  justifyContent: 'center',
  outline: 'none',
  alignItems: 'center',
  marginRight: theme.spacing(1.2),
  padding: theme.spacing(1),
  cursor: 'pointer',
  borderRadius: '50%',
  transition: '.5s all ease',
  '& svg ': {
    color: theme.palette.secondary.dark,
    transform: 'scale(1.3)',
    padding: '2px',
    transition: '.2s all ease',
  },
  '&:hover svg': {
    color: theme.palette.white?.main,
    transform: 'scale(1.4)',
  },
  '&:hover': {
    border: '1px solid transparent',
    background: theme.palette.primary.main,
  },
}));

export default Wrapper;
