import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import React from 'react';
import { StyledTimeLine, Content, Name, Title, Date, StyledSeparator, StyledContent } from './styles';
import { TimelineConnector, TimelineDot } from '@mui/lab';

const TimeLineComponent = ({ item }) => {
  return (
    <StyledTimeLine>
      <Timeline position="alternate">
        {item?.map((user, index) => (
          <TimelineItem key={index}>
            <StyledSeparator>
              <TimelineDot color={`${index === 0 ? 'primary' : 'grey'}`} />
              <TimelineConnector />
            </StyledSeparator>
            <StyledContent>
              <Title>{user.company}</Title>
              <Date>{user.duration}</Date>
              <Name>{user.technology}</Name>
              <Content>{user.role}</Content>
            </StyledContent>
          </TimelineItem>
        ))}
      </Timeline>
    </StyledTimeLine>
  );
};

export default TimeLineComponent;
