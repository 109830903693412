import { Box, Container, Grid, Typography, styled } from '@mui/material';
export const Wrapper = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}));
export const ServicesItemWrapper = styled(Box)(
  ({
    theme: {
      palette: { lightnessWhite },
      breakpoints,
      spacing,
    },
  }) => ({
    backgroundColor: lightnessWhite.main,
    padding: spacing(6),
    [breakpoints?.down('md')]: {
      padding: spacing(0),
    },
  })
);
export const StyledContainer = styled(Grid)(() => ({
  '& img': {
    width: '100%',
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  lineHeight: '1.5',
  letterSpacing: '1px',
  fontSize: '1rem',
  color: theme.palette.black.light,
  padding: theme.spacing(5, 0),
  [theme.breakpoints?.down('md')]: {
    fontSize: '.825rem',
    textAlign: 'center',
    padding: theme.spacing(2, 0),
  },
}));
