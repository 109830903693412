import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

export const ImageWrapper = styled.div`
  img {
    transition: all 0.3s ease-in-out;
  }
  &:hover img {
    transform: scale(1.1);
  }
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`;
export const DetailsWrapper = styled.div(({ theme }) => ({
  marginTop: ` ${theme.spacing(1.3)}`,
}));

export const UserDetails = styled('p')(({ theme }) => ({
  margin: '0',
  color: theme.palette.black.main,
  fontWeight: '300',
  letterSpacing: '1.3px',
  fontSize: '0.875rem',
  marginTop: theme.spacing(2),
}));
export const UserName = styled('h4')(
  ({
    theme: {
      palette: { primaryDark },
    },
  }) => ({
    margin: '0',
    color: primaryDark.main,
    fontWeight: '600',
    fontSize: '1.3rem',
  })
);

export const ProfileWrapper = styled(Box)(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    background: white?.main,
    overflow: 'hidden',
    cursor: 'pointer',
    borderRadius: '4px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  })
);
export const UserRole = styled('h4')(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: '0.8rem',
  fontWeight: '600',
  color: theme.palette.black?.main,
  margin: `${theme.spacing(2.3)} 0`,
  letterSpacing: '1px',
}));

export const Image = styled('img')({
  objectFit: 'cover',
  height: '264px',
  width: '100%',
});

export const ProfileContentWrapper = styled.div(({ theme }) => ({
  padding: theme.spacing(2.8),
}));
export const IconsContentWrapper = styled.div(({ theme }) => ({
  paddingLeft: theme.spacing(2.8),
  paddingRight: theme.spacing(2.8),
  paddingBottom: theme.spacing(2.8),
}));

export const SocialIcons = styled.div(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2.8),
  columnGap: theme.spacing(2.8),
}));
export const IconsWrapper = styled(Box)(({ theme }) => ({
  // marginTop: theme.spacing(3),
  display: 'flex',
  paddingRight: theme.spacing(3),
}));
export const IconBorder = styled(Link)(({ theme }) => ({
  minHeight: theme.spacing(2.9),
  minWidth: theme.spacing(2.9),
  border: `1px solid ${theme.palette.primaryDark?.main}`,
  display: 'flex',
  justifyContent: 'center',
  outline: 'none',
  alignItems: 'center',
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  cursor: 'pointer',
  borderRadius: '50%',
  transition: '.5s all ease',
  '& svg': {
    color: theme.palette.primaryDark?.main,
    transform: 'scale(1.3)',
    padding: theme.spacing(0.2),
    transition: '.2s all ease',
  },
  '&:hover svg': {
    color: theme.palette.white?.main,
    transform: 'scale(1.4)',
  },
  '&:hover': {
    border: '1px solid transparent',
    background: theme.palette.primaryDark.main,
  },
}));
