import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

export const CardContainer = styled(Box)(({ theme, variant }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  cursor: 'pointer',
  maxWidth: '100%',
  minHeight: variant === 'onBoarding' ? `${theme.spacing(25)}` : '270px',
  padding: variant === 'onBoarding' ? `${theme.spacing(4, 2)}` : `${theme.spacing(1, 2)}`,
  justifyContent: variant === 'onBoarding' ? 'space-around' : 'start',
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  background: variant === 'onBoarding' ? 'white' : '#002d5e',
  color: variant === 'onBoarding' ? 'unset' : 'white',
  ':hover': {
    transform: 'scale(1.02)',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    background: theme.palette.white.main,
    color: 'black',
  },
}));

export const TitleWrapper = styled(Box)({
  textAlign: 'center',
});
export const Number = styled(Typography)(({ theme }) => {
  return {
    fontWeight: '700',
    fontSize: '3rem',
    color: theme.palette.primary?.dark,
  };
});

export const Title = styled.p(({ theme }) => ({
  textTransform: 'capitalize',
  margin: '0',
  padding: '0',
  lineHeight: theme.spacing(3),
  textAlign: 'center',
  height: '65px',
}));

export const StyledTitle = styled('h4')(({ theme }) => ({
  margin: '0',
  letterSpacing: '0.125rem',
  fontWeight: '700',
  lineHeight: '1.5',
  marginBottom: theme.spacing(2),
}));

export const StyledTypo = styled('h4')(({ theme }) => ({
  margin: '0',
  letterSpacing: '0.125rem',
  fontWeight: '400',
  lineHeight: '1.5',
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));
export const StyledSecondTypo = styled('h3')(({ theme }) => ({
  margin: '0',
  letterSpacing: '0.125rem',
  fontWeight: '400',
  lineHeight: '1.5',
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));
