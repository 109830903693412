import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import {
  GuidBox,
  GuidContent,
  StyledCircularProgress,
  StyledHeadingWrapper,
  StyledProgressContainer,
  StyledSection,
  StyledServicesDesc,
  StyledServicesMain,
  StyledServicesTypo,
} from './styles';
import ServicesCard from '../../components/ServicesCard';
import Viewed from '../../components/viewItems';
import MainSection from '../../components/Layout';
import { useEffect, useState } from 'react';
import sanityClient from '../../client';
import imageUrlBuilder from '@sanity/image-url';
import client from '../../client';

const Services = () => {
  const [servicesList, setServicesList] = useState({});
  const [servicesHeadings, setServicesHeadings] = useState({});
  const [mostViewed, setMostViewed] = useState({});

  const builder = imageUrlBuilder(client);

  // Define the order of the special titles
  const specialOrderTitles = ['Xact Financials', 'Comverse', 'Archified', 'Sapienx Iris', 'ReachFlair', 'NorthSpex'];

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "servicesList"][0]`)
      .then((data) => setServicesList(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "mostViewed"][0]`)
      .then((data) => setMostViewed(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "servicesHeadings"][0]`)
      .then((data) => setServicesHeadings(data))
      .catch(console.error);
  }, []);

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <Box>
      <MainSection />
      <Container>
        <StyledHeadingWrapper>
          <StyledServicesTypo variant="h5">{servicesHeadings?.heading}</StyledServicesTypo>
          <StyledServicesMain variant="h4">{servicesHeadings?.mainHeading}</StyledServicesMain>
          <StyledServicesDesc variant="h6">{servicesHeadings?.description}</StyledServicesDesc>
        </StyledHeadingWrapper>
        <StyledSection>
          <Grid container spacing={[3]}>
            {servicesList?.services?.length &&
              servicesList?.services
                ?.sort((a, b) => {
                  const indexA = specialOrderTitles.indexOf(a.title);
                  const indexB = specialOrderTitles.indexOf(b.title);

                  if (indexA !== -1 || indexB !== -1) {
                    if (indexA === -1) {
                      return 1;
                    }
                    if (indexB === -1) {
                      return -1;
                    }
                    return indexA - indexB;
                  }

                  const titleA = a.title.toLowerCase();
                  const titleB = b.title.toLowerCase();
                  if (titleA < titleB) {
                    return -1;
                  }
                  if (titleA > titleB) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((item, key) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={`service-${key}`}>
                      <ServicesCard item={item} />
                    </Grid>
                  );
                })}
            {!servicesList?.services?.length && (
              <StyledProgressContainer>
                <StyledCircularProgress />
              </StyledProgressContainer>
            )}
          </Grid>
        </StyledSection>
        <Grid container spacing={[1]}>
          <Grid item xs={12} sm={12} md={6}>
            <GuidBox image={mostViewed.image && urlFor(mostViewed.image)}>
              <GuidContent>
                <Typography variant="h4">{mostViewed.heading}</Typography>
                <Typography variant="body1">{mostViewed.description}</Typography>
              </GuidContent>
            </GuidBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Viewed item={mostViewed} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default React.memo(Services);
