import styled from '@emotion/styled';
import { Toolbar, AppBar } from '@mui/material';
import { Box } from '@mui/material';
import Button from '../Button';

export const Navbar = styled(AppBar)(({ scroll, theme }) => {
  const transparent = parseInt(scroll) < 100;
  return {
    height: '80px',
    boxSizing: 'border-box',
    justifyContent: 'center',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    boxShadow: transparent ? 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' : ` rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px`,
    transition: ' .5s ease',
    backgroundColor: theme.palette.white.main,
    zIndex: '999',
    position: 'unset',
    '.MuiToolbar-root': {
      padding: '0',
    },
    '.nav-links button': {
      color: theme.palette.black.main,
      transition: '.4s ease-in-out',
    },
    '.h-100': {
      height: '100%',
      boxSizing: 'border-box',
    },
  };
});

export const StyledMenuIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: theme.spacing(2.8),

  button: {
    padding: theme.spacing(1.3),
    svg: {
      fontSize: 28,
    },
  },
}));
export const LinksWrapper = styled(Box)({
  flexGrow: 1,
  justifyContent: 'center',
  display: 'flex',
});
export const LogoWrapper = styled(Box)(({ theme }) => ({
  width: '160px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  cursor: 'pointer',
  '& img': {
    height: '100%',
    width: '100%',
  },
  '&.sm-logo': {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('md')]: {
    // '&.sm-hide': {
    //   display: 'none',
    // },
  },
}));

export const ContactUs = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2.8),
  transition: '.5s ease',
}));
export const ToolbarBox = styled(Toolbar)(
  ({
    theme: {
      palette: { primary },
      spacing,
    },
    transparent,
  }) => ({
    position: 'relative',
    '& .MuiBox-root .btn.MuiButtonBase-root.MuiButton-root': {
      margin: spacing(0, 1.5),
    },

    '& .btn:hover': {
      color: primary?.main,
    },
    '.btn.MuiButtonBase-root.MuiButton-root': {
      transition: 'all .2s ease',
      borderBottom: '2px solid transparent',
      borderRadius: ' 0',
      fontSize: ' 0.87rem',
      padding: spacing(0, 1.5),
    },
    '.btn.MuiButtonBase-root.MuiButton-root.active': {
      borderBottom: transparent ? '2px solid transparent' : `2px solid ${primary.main}`,
      color: primary?.main,
      borderRadius: '0',
      fontSize: ' 0.87rem',
    },
    justifyContent: 'space-between',
  })
);
export const Sidebar = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.light,
  width: 250,
  padding: '20px',
  height: '100%',
  color: theme.palette.black.main,

  li: {
    marginBottom: '14px',
    padding: '16px 16px',
    transition: '.4s ease ',
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.primaryDark.main,
      color: theme.palette.secondary.light,
    },
  },
  '.active': {
    background: theme.palette.primaryDark.main,
    color: theme.palette.secondary.light,
  },
}));
