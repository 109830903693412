import React, { useState } from 'react';
import { Container, Grid } from '@mui/material';
import {
  ContactWrapper,
  FormWrapper,
  InputWrapper,
  StyledInput,
  StyledTextArea,
  ContactItem,
  IconsWrapper,
  Title,
  SubTitle,
  Text,
  ContactButton,
  IconBorder,
  StyledSection,
  ContactDetails,
} from './styles';
import SendIcon from '@mui/icons-material/Send';
import { useLocation } from 'react-router-dom';
import Heading from '../MainHeading';
import Facebook from '../../components/Svgs/Facebook';
import LinkedIn from '../Svgs/Linkedin';
import Twitter from '../Svgs/Twitter';
import Mail from '../Svgs/Mail';
import InstagramIcon from '../Svgs/Instagram';

const ContactForm = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const contactDetails = [
    {
      label: 'contact@calibreoninternational.com',
    },
    {
      label: '+9258114-55703',
    },
  ];
  const visit = [
    {
      label: 'Near Heli Chowk Zulfiqarabad Gilgit',
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEmail = () => {
    const emailBody = `Hi,

    ${formData.message}

    Sincerely,
    ${formData.name}
    Email: ${formData.email}
    `;

    if ((formData.email.length && formData.message.length, formData.name.length)) {
      return (
        window.open(
          `mailto:calibreoninternationalofficial@gmail.com?subject=subject&body=${encodeURIComponent(emailBody)}`
        ),
        setFormData({
          name: '',
          email: '',
          message: '',
        })
      );
    }
  };

  return (
    <StyledSection>
      <Container>
        {location.pathname === '/contact' ? null : (
          <>
            <Heading
              variant="h2"
              color={'primary'}
              align="center"
              subTitle="Get in touch"
              title="contact us."
            ></Heading>
          </>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormWrapper>
              <Title variant="h4">Get in touch</Title>
              <InputWrapper>
                <StyledInput placeholder="Name" name="name" value={formData.name} onChange={handleChange} />
                <StyledInput placeholder="Email Adress" name="email" value={formData.email} onChange={handleChange} />
                <StyledTextArea
                  aria-label="Hello"
                  placeholder="Message"
                  minRows={5}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </InputWrapper>
              <ContactButton variant="contained" color="primaryDark" onClick={handleEmail}>
                Send Message <SendIcon className="ml-2" fontSize="small" />
              </ContactButton>
            </FormWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactWrapper>
              <Title variant="h4">Contact information</Title>
              <ContactDetails>
                <SubTitle>Let's talk</SubTitle>
                {contactDetails.map(({ label }, index) => (
                  <Text key={index} title="details">
                    {label}
                  </Text>
                ))}
              </ContactDetails>
              <SubTitle>Meet us</SubTitle>
              {visit.map(({ label }, index) => (
                <ContactItem key={`contact-${index}`}>
                  <Text title="details">{label}</Text>
                </ContactItem>
              ))}
              <IconsWrapper>
                <IconBorder to="https://www.facebook.com/calibreon.international" target="_blank">
                  <Facebook className="social-icon" />
                </IconBorder>
                <IconBorder
                  to="https://www.linkedin.com/company/calibreon-international/mycompany/?viewAsMember=true"
                  target="_blank"
                >
                  <LinkedIn className="social-icon" />
                </IconBorder>
                <IconBorder to="https://www.instagram.com/calibreon_international/" target="_blank">
                  <InstagramIcon color={'#fff'} height={10} />
                </IconBorder>
                <IconBorder
                  to="https://twitter.com/i/flow/login?redirect_after_login=%2Fi%2Fflow%2Flogin"
                  target="_blank"
                >
                  <Twitter className="social-icon" />
                </IconBorder>
                <IconBorder
                  to="#"
                  onClick={() => window.open(`mailto:calibreoninternationalofficial@gmail.com`)}
                  target="_blank"
                >
                  <Mail className="socialIcon" />
                </IconBorder>
              </IconsWrapper>
            </ContactWrapper>
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
  );
};

export default ContactForm;
