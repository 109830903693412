import React from 'react';
import { Wrapper, Label, Img } from './styles';
import imageUrlBuilder from '@sanity/image-url';
import client from '../../client';

const Sponsor = ({ url, image, label }) => {
  const builder = imageUrlBuilder(client);
  const urlFor = (source) => {
    return builder.image(source);
  };
  return (
    <Wrapper>
      <Label href={url} target="_blank" className="sponsor_url">
        {label}
      </Label>
      {image && <Img className="sponsor_img" src={urlFor(image)} />}
    </Wrapper>
  );
};

export default Sponsor;
