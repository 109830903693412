import styled from '@emotion/styled';
import { Typography, Box, Grid, Container, CircularProgress, Button } from '@mui/material';

export const MissionDesc = styled(Typography)(({ theme }) => ({
  letterSpacing: '1px',
  fontSize: '1rem',
  color: theme.palette.black?.light,
  lineHeight: '1.5',
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export const StyledBox = styled(Box)(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    backgroundColor: white?.main,
  })
);
export const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(8),
}));
export const SecondContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(19),
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(0),
  },
}));
export const ImagesBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  marginTop: theme.spacing(14),
  img: {
    borderRadius: '5px 5px 5px 5px',
  },

  '& .absolatedImage': {
    position: 'absolute',
    '& :first-of-type': {
      width: '280px',
      textAlign: 'center',
      top: '218px',
      left: '-430px',
      position: 'absolute',
    },
    '& :last-child': {
      left: '170px',
      top: '160px',
      width: '320px',
      textAlign: 'center',
      position: 'absolute',
    },
    [theme?.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  '.anime-1': {
    '@keyframes zoomIn': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.5)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
    animation: 'zoomIn 12s infinite ease',
  },
  '.anime-2': {
    '@keyframes slideRight': {
      '0%': {
        transform: 'translateX(0)',
      },
      '50%': {
        transform: 'translateX(60px)',
      },
      '100%': {
        transform: 'translateX(0px)',
      },
    },
    animation: 'slideRight 12s infinite ease',
    [theme?.breakpoints.down('md')]: {
      animation: 'unset',
      width: '100%',
    },
  },
  '.anime-3': {
    '@keyframes slideLeft': {
      '0%': {
        transform: 'translateX(0)',
      },
      '50%': {
        transform: 'translateX(-60px)',
      },
      '100%': {
        transform: 'translateX(0px)',
      },
    },
    animation: 'slideLeft 12s infinite ease',
  },
}));
export const TeamBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 0, 4.6, 0),
  backgroundColor: theme.palette.white?.main,
  position: 'relative',
  textAlign: 'center',
  marginTop: theme.spacing(2),
}));
export const TeamCards = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(16),
  padding: '0',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
  },
}));
export const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(9),
  },
}));

export const StyledProgressContainer = styled(Box)({
  height: '300px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  width: ' 100%',
});

export const StyledCircularProgress = styled(CircularProgress)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ContactUs = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2.8),
  transition: '.5s ease',
}));

export const StyledAnimationConatiner = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '12px',
  img: {
    borderRadius: '20px',
  },
});

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const ImageContainer = styled(Grid)`
  & img {
    border-radius: 20px;
  }
`;

export const StyledDistributer = styled(Box)`
  width: 100%;
  background-color: #e4e6ea;
  height: 2px;
  margin-top: 50px;
`;

export const StyledMainTypo = styled(Typography)({
  fontSize: '42px',
  fontWeight: 700,
});

export const StyledMainDescTypo = styled(Typography)(({ theme }) => ({
  marginTop: '30px',
  color: theme.palette.black?.light,
}));
