import User1 from '../../assets/images/all-team-user1.jpg';
import User2 from '../../assets/images/all-team-user2.jpg';
import User3 from '../../assets/images/all-team-user3.jpg';
import User4 from '../../assets/images/all-team-user4.jpg';
import User5 from '../../assets/images/all-team-user5.jpg';

export const teamMembers = [
  {
    data: [
      {
        name: 'Mian Dad',
        role: 'Bookkeeper',
        img: User1,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },

      {
        name: 'Fayyaz',
        role: 'Web Developer',
        img: User2,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
      {
        name: 'Aleem',
        role: 'Web Developer',
        img: User4,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
      {
        name: 'Qasim',
        role: 'Web Developer',
        img: User1,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
      {
        name: 'Aleem',
        role: 'Web Developer',
        img: User5,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },

      {
        name: 'Kaleem',
        role: 'Web Developer',
        img: User4,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
      {
        name: 'Qasim',
        role: 'Web Developer',
        img: User3,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
      {
        name: 'Aleem',
        role: 'Web Developer',
        img: User1,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
      {
        name: 'Jack',
        role: 'Digital Marketing',
        img: User2,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
      {
        name: 'Qasim',
        role: 'Web Developer',
        img: User3,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
      {
        name: 'Aleem',
        role: 'Web Developer',
        img: User1,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
      {
        name: 'Jack',
        role: 'Digital Marketing',
        img: User2,
        userDetails:
          'Glavi amet ritnisl libero molestie ante ut fringilla purus eros quis glavrid from dolor amet iquam lorem bibendum',
      },
    ],
  },
];
