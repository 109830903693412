import React from 'react';
import { Grid } from '@mui/material';
import {
  BannerMainWrapper,
  StyledTypo,
  LeftContent,
  StyledContent,
  ImageWrapper,
  GetStartBtn,
  Review,
  UpworkLogo,
  RatedTypo,
  ImageContainer,
} from './styles';
import Upwork from '../../assets/images/Upwork-Logo-1.png';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import client from '../../client';
import imageUrlBuilder from '@sanity/image-url';
import { StyledCircularProgress, StyledProgressContainer } from '../services/styles';

const Banner = ({ hero }) => {
  const { description, image, rating, title } = hero;

  const builder = imageUrlBuilder(client);

  const handleClick = (event) => {
    event.preventDefault();
    const targetElement = document.getElementById('target-element');

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Target element not found or is null.');
    }
  };

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <BannerMainWrapper>
      <Grid container alignItems={'center'} gap={2}>
        <LeftContent>
          <StyledTypo>{title}</StyledTypo>
          <StyledContent variant="h6">{description}</StyledContent>
          <Review>
            <UpworkLogo>
              <img src={Upwork} alt="Upwork" />
            </UpworkLogo>
            <Stack>
              <Rating name="half-rating" value={rating || 4.5} precision={0.5} readOnly />
            </Stack>
            <RatedTypo>{`Rated ${rating || 4.5}/5`}</RatedTypo>
          </Review>
          <GetStartBtn size="lg" variant="outlined" href="#target-element" onClick={handleClick}>
            Get Started
          </GetStartBtn>
        </LeftContent>
        <ImageWrapper>
          <ImageContainer>
            {image ? (
              <img src={urlFor(image)} alt="Hero-section-img" />
            ) : (
              <StyledProgressContainer>
                <StyledCircularProgress />
              </StyledProgressContainer>
            )}
          </ImageContainer>
        </ImageWrapper>
      </Grid>
    </BannerMainWrapper>
  );
};

export default Banner;
