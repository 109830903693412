import React from 'react';
import { CardContainer, Number, StyledTitle, StyledTypo, Title, TitleWrapper } from './styles';

const OnboardingCard = ({ title, number, variant }) => {
  if (variant === 'onBoarding') {
    return (
      <CardContainer variant={variant}>
        <TitleWrapper>
          <Number>{number}</Number>
          <Title>{title}</Title>
        </TitleWrapper>
      </CardContainer>
    );
  } else {
    return (
      <CardContainer variant={variant}>
        <TitleWrapper>
          <StyledTitle>{number}</StyledTitle>
          <StyledTypo>{title}</StyledTypo>
        </TitleWrapper>
      </CardContainer>
    );
  }
};

export default OnboardingCard;
