import React, { useState } from 'react';
import Wrapper, { SubscribeButton, Input, Label, News, NewsWrapper, TextWrapper } from './styles';

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  return (
    <NewsWrapper>
      <News>
        <TextWrapper>
          <Label>Subscribe to get updates directly</Label>
        </TextWrapper>
        <Wrapper component="form">
          <Input placeholder="Your Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <SubscribeButton
            size="lg"
            variant="contained"
            color="black"
            onClick={() => {
              return (
                window.open(
                  `mailto:calibreoninternationalofficial@gmail.com?subject=Subscriptions to News Letter&body=${email}`
                ),
                setEmail('')
              );
            }}
          >
            Subscribe
          </SubscribeButton>
        </Wrapper>
      </News>
    </NewsWrapper>
  );
};

export default NewsLetter;
