import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const MapSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  display: 'flex',
  alignItems: 'center',
  '& iframe': {
    width: '100%',
    height: '30rem',
  },
  [theme?.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}));
