import React, { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import {
  StyledBox,
  MissionDesc,
  ImagesBox,
  TeamBox,
  TeamCards,
  StyledGrid,
  StyledContainer,
  SecondContainer,
  ContactUs,
  StyledAnimationConatiner,
  ButtonContainer,
  ImageContainer,
  StyledDistributer,
  StyledMainTypo,
  StyledMainDescTypo,
} from './styles';
import { SlideWrapper, HeadingWrapper } from '../../pages/home/styles';
import TeamCard from '../teamCard';
import Heading from '../Heading/index';
import CardHeading from '../../components/MainHeading';
import sanityClient from './../../client';
import imageUrlBuilder from '@sanity/image-url';
import client from './../../client';
import { StyledCircularProgress, StyledProgressContainer } from '../../pages/services/styles';
import Carousel from '../Slider';
import TestimonialsCard from '../testimonialsCard';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useNavigate } from 'react-router-dom';
import Section from '../Section';

const Mission = () => {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState({});
  const [aboutUs, setAboutUs] = useState({});
  const { images } = aboutUs;
  const [team, setTeam] = useState({});

  const builder = imageUrlBuilder(client);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "aboutUs"][0]`)
      .then((data) => setAboutUs(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "testimonials"][0]`)
      .then((data) => setTestimonials(data))
      .catch(console.error);

    sanityClient
      .fetch(`*[_type == "team"][0]`)
      .then((data) => setTeam(data))
      .catch(console.error);
  }, []);

  const urlFor = (source) => {
    return builder.image(source);
  };

  const onClickContact = () => {
    navigate('/contact');
  };

  return (
    <StyledBox>
      <StyledContainer>
        <Grid container spacing={[4]} mb={5} justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <ButtonContainer>
              <StyledMainTypo variant="h1">{aboutUs?.mainHeading}</StyledMainTypo>
            </ButtonContainer>
            <StyledMainDescTypo variant="h5">{aboutUs?.description}</StyledMainDescTypo>
          </Grid>
        </Grid>
      </StyledContainer>
      {aboutUs?.introduction ? (
        <StyledContainer>
          <Grid container spacing={[4]}>
            <Grid item xs={12} sm={12} md={6}>
              <Heading title={aboutUs?.introduction?.heading} />
              <MissionDesc>{aboutUs?.introduction?.content}</MissionDesc>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <StyledAnimationConatiner>
                <img
                  height={300}
                  src={aboutUs?.introduction?.image && urlFor(aboutUs?.introduction?.image)}
                  alt="Animation CI"
                />
              </StyledAnimationConatiner>
            </Grid>
          </Grid>
        </StyledContainer>
      ) : (
        <StyledProgressContainer>
          <StyledCircularProgress />
        </StyledProgressContainer>
      )}
      {aboutUs?.bio?.map((info, index) => {
        const { content, heading, image, bulletPoints } = info;
        if (index % 2 !== 0) {
          return (
            <StyledContainer key={index}>
              <Grid container spacing={[4]} justifyContent="flex-end" sx={{ alignItems: 'center' }}>
                <Grid item xs={12} sm={12} md={6}>
                  <Heading title={heading} />
                  <MissionDesc>{content}</MissionDesc>
                  {bulletPoints && (
                    <ul>
                      {bulletPoints?.map((content) => {
                        return (
                          <li>
                            <MissionDesc>{content?.children[0]?.text}</MissionDesc>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Grid>
                <ImageContainer item xs={12} sm={12} md={6}>
                  <img src={image && urlFor(image)} width={'100%'} height={'auto'} alt="Journey" />
                </ImageContainer>
              </Grid>
            </StyledContainer>
          );
        } else {
          return (
            <StyledContainer key={index}>
              <Grid container spacing={[4]} sx={{ alignItems: 'center' }}>
                <ImageContainer item xs={12} sm={12} md={6}>
                  <img src={image && urlFor(image)} width={'100%'} height={'auto'} alt="Journey" />
                </ImageContainer>
                <Grid item xs={12} sm={12} md={6}>
                  <Heading title={heading} />
                  <MissionDesc>{content}</MissionDesc>
                  {bulletPoints && (
                    <ul>
                      {bulletPoints?.map((content) => {
                        return (
                          <li>
                            <MissionDesc>{content?.children[0]?.text}</MissionDesc>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Grid>
              </Grid>
            </StyledContainer>
          );
        }
      })}
      <StyledDistributer />
      <StyledContainer>
        <Grid container spacing={[4]} mb={5} justifyContent="center">
          <Grid item xs={12} sm={12} md={6} textAlign="center">
            <ButtonContainer>
              <Heading title={aboutUs?.getInTouch?.heading} />
            </ButtonContainer>
            <MissionDesc>{aboutUs?.getInTouch?.content}</MissionDesc>
            <ButtonContainer>
              <ContactUs size="lg" variant="outlined" color="primaryDark" onClick={onClickContact}>
                Contact
              </ContactUs>
            </ButtonContainer>
          </Grid>
        </Grid>
      </StyledContainer>
      <Section variant="primaryDark">
        <SlideWrapper>
          <Container>
            <HeadingWrapper>
              <CardHeading marginbottomzero={'true'} title={'testimonial.'} subTitle={'what people say?'} />
            </HeadingWrapper>
            <Carousel dots>
              {testimonials?.testimonialsList?.length ? (
                testimonials?.testimonialsList?.map((item, id) => <TestimonialsCard key={id} item={item} />)
              ) : (
                <StyledProgressContainer sx={{ display: 'flex !important' }}>
                  There are no Testimonials! &nbsp; <SentimentVeryDissatisfiedIcon />
                </StyledProgressContainer>
              )}
            </Carousel>
          </Container>
        </SlideWrapper>
      </Section>
      {images ? (
        <Container>
          <ImagesBox>
            <img src={images && urlFor(images[0])} alt="img1" className="anime-2" width={460} />
            <Box className="absolatedImage">
              <img src={images && urlFor(images[1])} alt="img2" className="anime-1" />
              <img src={images && urlFor(images[2])} alt="img3" className="anime-3" />
            </Box>
          </ImagesBox>
        </Container>
      ) : (
        <StyledProgressContainer>
          <StyledCircularProgress />
        </StyledProgressContainer>
      )}
      <SecondContainer>
        <TeamBox>
          <Heading title={team?.heading} subTitle={team?.subHeading} dark />
        </TeamBox>
        <TeamCards>
          <Grid container spacing={2}>
            {team?.teamList?.map((item, index) => {
              const { introduction, image, title, designation, abbreviation } = item;
              const userBio = introduction[0]?.teamBio;
              return (
                <StyledGrid item xs={12} sm={6} md={3} key={`user-${index}`}>
                  <TeamCard
                    userImg={image}
                    userName={title}
                    userRole={designation}
                    userDetails={userBio}
                    abbreviation={abbreviation}
                  />
                </StyledGrid>
              );
            })}
          </Grid>
        </TeamCards>
      </SecondContainer>
    </StyledBox>
  );
};

export default React.memo(Mission);
