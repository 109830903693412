import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Image,
  ImageWrapper,
  ServicesWrapper,
  UserName,
  ServicesContentWrapper,
  DetailsWrapper,
  ServicesDetails,
} from './styles';

import imageUrlBuilder from '@sanity/image-url';
import client from '../../client';
import Button from '../Button';

const ServicesCard = ({ item }) => {
  const builder = imageUrlBuilder(client);
  const { title, image, description, content } = item;

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/services/${String(title).replace(/\s/g, '').toLocaleLowerCase()}`, {
      state: {
        title,
        content,
      },
    });
  };

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <ServicesWrapper>
      <ImageWrapper>{image && <Image src={urlFor(image)} alt={title} />}</ImageWrapper>
      <ServicesContentWrapper>
        <UserName>{title}</UserName>
        <DetailsWrapper>
          <ServicesDetails>{description}</ServicesDetails>
        </DetailsWrapper>
        <Button variant="outlined" color="primaryDark" onClick={handleClick}>
          Learn More
        </Button>
      </ServicesContentWrapper>
    </ServicesWrapper>
  );
};

export default ServicesCard;
