import styled from '@emotion/styled';
import { Box } from '@mui/material';

const Wrapper = styled(Box)(
  ({
    theme: {
      palette: { mediumGray },
    },
  }) => ({
    height: '170px',
    minWidth: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    '&.size': {
      '& svg': {
        width: '70px',
        height: '70px',
      },
      '& .css-2u009e': {
        fontSize: '25px',
      },
      '& .css-1ta1ohp': {
        fontSize: '15px',
      },
    },
  })
);

export const AchievementTotall = styled('h1')(
  ({
    theme: {
      palette: { white, black },
    },
    textLight,
  }) => ({
    color: textLight ? white?.main : black.main,
    fontWeight: '600',
    margin: '0',
    marginTop: '8px',
    letterSpacing: '2px',
  })
);
export const SubTitle = styled('p')(
  ({
    theme: {
      palette: { black, secondary },
    },
    textLight,
  }) => ({
    fontSize: '14px',
    fontWeight: '300',
    letterSpacing: '2px',
    margin: '0',
    color: textLight ? secondary.light : black.light,
  })
);

export default Wrapper;
