import styled from '@emotion/styled';
import { Box, ListItem } from '@mui/material';

export const DetailsWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.4),
}));
export const StyledListItem = styled(ListItem)(({ theme }) => ({
  span: {
    fontWeight: '500',
    fontSize: '1rem',
  },
  p: {
    fontSize: '1rem',
    span: {
      fontWeight: '400',
    },
  },
  [theme.breakpoints.down('md')]: {
    span: {
      fontSize: '.9rem',
    },
    p: {
      fontSize: '.7rem',
    },
  },
}));

export const ViewedWrapper = styled('div')(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    width: '100%',
    background: white?.main,
  })
);
export const UserRole = styled('h4')(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: '400',
  color: theme.palette.primary?.main,
  margin: theme.spacing(0, 0, 1, 0),
  letterSpacing: '1px',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
  },
}));

export const ViewedContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.7, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0.7, 0),
  },
}));
