import { InputBase } from '@mui/material';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import Button from '../Button';
const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'none',
  borderRadius: '4px',
  '& input': {
    border: `1px solid ${theme.palette.primary.main}`,
    height: '48px',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    transition: '.4s all ease',
    boxSizing: 'border-box',
    width: '100%',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': { borderColor: theme.palette.main },
  },
}));
export const TextWrapper = styled(Box)(({ theme }) => ({}));
export const Label = styled('h3')(({ theme }) => ({
  color: theme.palette.black.light,
  fontWeight: '500',
  letterSpacing: '0.075rem',
  fontSize: '1.0625rem',
  textAlign: 'left',
  marginTop: '0',
}));

export const SubscribeButton = styled(Button)({
  right: '0',
  fontSize: '1rem',
  transition: '0.6s all ease',
  height: '3rem',
});

export const NewsWrapper = styled(`div`)(({ theme }) => ({
  width: ' 100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  padding: theme.spacing(0),
  [theme?.breakpoints.down('md')]: {
    flexDirection: 'unset',
    justifyContent: 'start',
    padding: '0 ',
    marginTop: theme.spacing(5),
  },
}));
export const News = styled(`div`)(() => ({}));
export const Input = styled(InputBase)(({ theme }) => ({
  minWidth: '21.875rem',
  [theme?.breakpoints.down('md')]: {
    minWidth: 'unset',
    manWidth: '16.875rem',
  },
}));

export default Wrapper;
