import { styled } from '@mui/system';
import { FormControl, Select } from '@mui/material';

const Control = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  background: theme.palette.primaryDark.main,
  color: theme.palette.white.main,

  '& fieldset': {
    borderColor: theme.palette.secondary.dark,
    borderRight: 'none',
    borderRadius: 'unset',
    width: '100% !important',
  },
  '& .MuiInputBase-root': {
    '&:hover fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.secondary.dark,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.secondary.dark,
    },
  },
  '& .MuiSelect-icon': {
    color: theme.palette.white.main,
    transition: 'transform 0.2s ease-in-out',
  },
  '& svg': {
    fontSize: '2rem',
  },
}));

export const StyledSelect = styled(Select)({
  margin: '0',
});
export const DefaultValue = styled('p')({
  margin: '0',
  letterSpacing: '.9px',
  fontSize: '1.5rem',
  fontWeight: '400',
});
export const Item = styled('p')(({ theme }) => ({
  margin: '0',
  padding: '0',
  letterSpacing: '.9px',
  fontSize: '1.5rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: theme.spacing(1.5),
}));

export default Control;
