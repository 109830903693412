import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ServicesWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  transition: '0.1s all ',
  padding: theme.spacing(4, 1),
  border: `1px solid ${theme.palette.borderColor.main}`,
  cursor: 'pointer',
  boxSizing: 'border-box',
  height: '100%',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '110px',
  width: '140px',
}));

export const DetailsWrapper = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  margin: theme.spacing(1.5),
}));

export const ServicesDetails = styled('p')(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'wrap',
  padding: `${theme.spacing(1)}`,
  height: '2.5rem',
  margin: '0',
  lineHeight: '1.25rem',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  color: theme.palette.darkGray.main,
}));

export const UserName = styled('h4')(({ theme }) => ({
  fontWeight: 500,
  fontSize: '1.5rem',
  lineHeight: 1.2,
  color: theme.palette.primaryDark.main,
  margin: `${theme.spacing(1.5)} 0`,
}));

export const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  transitionDuration: '0.3s',
}));
export const DetailsSection = styled(Box)(({ theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  bottom: '0',
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: '0.3s all ease',
  '& svg': {
    color: theme.palette.primary.main,
    marginLeft: '.5rem',
    transition: '0.2s all ease',
  },
  '&:hover': {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    '.text': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    svg: {
      transform: `translate(${theme.spacing(2.5)})`,
    },
  },
}));

export const ServicesContentWrapper = styled(Box)({
  position: 'relative',
});
