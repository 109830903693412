import { CardMedia, Typography, styled } from '@mui/material';
import { Box } from '@mui/material';

export const TestimonialCard = styled(Box)({});

export const TestimonialDetails = styled('p')(({ theme }) => ({
  width: '70%',
  fontWeight: '300',
  fontSize: '1.125rem',
  color: theme.palette.secondary.light,
  lineHeight: '2',
  letterSpacing: '2px',
  marginTop: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
  },
}));

export const TestimonialContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  margin: theme.spacing(0, 4),
  marginBottom: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    margin: '0',
    marginBottom: theme.spacing(4),
  },
}));

export const TestimonialAuthor = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.3),
}));

export const AuthorImg = styled(Box)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  margin: '0 auto ' + theme.spacing(1),
  borderRadius: '50%',
  overflow: 'hidden',
  img: {
    width: '100%',
    height: 'auto',
  },
}));

export const AuthorName = styled(Typography)(
  ({
    theme: {
      palette: { primary },
      breakpoints,
    },
  }) => ({
    color: primary.main,
    letterSpacing: '2px',
    textTransform: 'uppercase',
    [breakpoints.down('md')]: {
      fontSize: '.6rem',
    },
  })
);

export const AuthorDetails = styled(Box)(({ theme }) => ({
  lineHeight: '2',
  fontSize: '0.8125rem',
  letterSpacing: '1px',
  [theme.breakpoints.down('md')]: {
    fontSize: '.6rem',
  },
}));

export const StyledVideoDiv = styled(CardMedia)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  width: 'unset',
  borderRadius: '2px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
  },
}));

export const ReviewContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const RatedTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontWeight: '500',
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    fontSize: '.8rem',
    marginTop: theme.spacing(0.6),
  },
}));
