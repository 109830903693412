import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ImageWrapper = styled.div(({ theme }) => ({
  margin: theme.spacing(-8, 0, 0, 0),
  marginBottom: theme.spacing(3.7),
  width: '140px',
  height: '140px',
  display: 'inline-block',
  cursor: 'pointer',
  transition: 'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s',
}));

export const DetailsWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.4),
}));

export const UserDetails = styled('h4')(({ theme }) => ({
  fontWeight: '300',
  letterSpacing: '1px',
  fontSize: '1rem',
  margin: '0',
  lineHeight: '1.5',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 7,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.palette.black.light,
}));

export const UserName = styled('h4')(({ theme }) => ({
  fontWeight: '500',
  fontSize: '1.4rem',
  lineHeight: '1.2',
  margin: '0',
  marginBottom: theme.spacing(1),
}));

export const UserRole = styled('h4')(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: '1rem',
  fontWeight: '400',
  color: theme.palette.primary?.main,
  margin: theme.spacing(0, 0, 1, 0),
  letterSpacing: '1px',
}));

export const Image = styled('img')({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  objectFit: 'cover',
  transitionDuration: '0.3s',
});
export const TeamCardWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.borderColor.main}`,
  height: '100%',
  textAlign: 'center',
  ' &:hover img': {
    transform: 'scale(1.1)',
  },
}));
export const TeamCardContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.8, 3, 2.8, 2.8),
}));
