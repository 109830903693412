import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import FooterItem from './footerItem';

import Wrapper, {
  StyledGrid,
  DesignedBy,
  Item,
  AddressWrapper,
  LinksWrapper,
  MenuLinks,
  Address,
  Contact,
  BorderRadius,
  SocialIcon,
  DepartmentWrapper,
  LogoWrapper,
} from './styles';
import Facebook from '../../components/Svgs/Facebook';
import Twitter from '../../components/Svgs/Twitter';
import Linkedin from '../../components/Svgs/Linkedin';
import InstagramIcon from '@mui/icons-material/Instagram';
import Mail from '../../components/Svgs/Mail';
import logoLight from '../../assets/images/Logo-light-new.png';
import sanityClient from '../../client';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const [servicesList, setServicesList] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "servicesList"][0]`)
      .then((data) => setServicesList(data))
      .catch(console.error);
  }, []);

  const routes = [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'Team',
      path: '/',
    },
    {
      name: 'Services',
      path: '/services',
    },
    {
      name: 'FAQs',
      path: '/faqs',
    },
    {
      name: 'About Us',
      path: '/about',
    },
  ];

  const handleClick = (page) => {
    const pageTitle = page.toLowerCase();
    const service = servicesList?.services?.find((service) => service.description?.toLowerCase()?.includes(pageTitle));

    if (service) {
      const { title, content } = service;
      navigate(`/services/${title.replace(/\s/g, '').toLowerCase()}`, {
        state: { title, content },
      });
    } else {
      navigate(`/services`);
    }
  };

  return (
    <Wrapper>
      <Container>
        <StyledGrid container spacing={4}>
          <StyledGrid container item sm={12} md={4}>
            <StyledGrid item xs={12} md={12}>
              <Item>
                <LogoWrapper>
                  <img src={logoLight} alt="Logo-Light" />
                </LogoWrapper>
                <FooterItem>
                  <AddressWrapper>
                    <Address>
                      Experienced and world-class remote talent for hire. Let us create better customer experiences that
                      help your brand grow.
                    </Address>
                  </AddressWrapper>
                  <SocialIcon>
                    <BorderRadius
                      to="https://www.facebook.com/profile.php?id=61553959177675&mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <Facebook color={'#fff'} height={25} />
                    </BorderRadius>
                    <BorderRadius
                      to="https://twitter.com/i/flow/login?redirect_after_login=%2Fi%2Fflow%2Flogin"
                      target="_blank"
                    >
                      <Twitter color={'#fff'} height={25} />
                    </BorderRadius>
                    <BorderRadius
                      to="https://www.linkedin.com/company/calibreon-international/mycompany/?viewAsMember=true"
                      target="_blank"
                    >
                      <Linkedin color={'#fff'} height={25} />
                    </BorderRadius>
                    <BorderRadius to="https://www.instagram.com/calibreon_international/" target="_blank">
                      <InstagramIcon color={'#fff'} height={10} />
                    </BorderRadius>
                    <BorderRadius
                      to="#"
                      onClick={() => window.open(`mailto:calibreoninternationalofficial@gmail.com`)}
                      target="_blank"
                    >
                      <Mail color={'#fff'} height={25} />
                    </BorderRadius>
                  </SocialIcon>
                </FooterItem>
              </Item>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid container item xs={12} md={8}>
            <StyledGrid item md={4}>
              <Item>
                <FooterItem title={'Menu'}>
                  <LinksWrapper>
                    {routes.map((item) => {
                      return (
                        <MenuLinks href={item.path} key={`route-${item.name}`}>
                          {item.name}
                        </MenuLinks>
                      );
                    })}
                  </LinksWrapper>
                </FooterItem>
              </Item>
            </StyledGrid>
            <StyledGrid item xs={12} md={4}>
              <FooterItem title={'Services'} />
              <DepartmentWrapper>
                <MenuLinks onClick={() => handleClick('Accounting & Finance')}>Accounting</MenuLinks>
                <MenuLinks onClick={() => handleClick('Digital Marketing')}>Digital Marketing</MenuLinks>
                <MenuLinks onClick={() => handleClick('Architectural Design & Quantity Surveying')}>
                  Engineering
                </MenuLinks>
                <MenuLinks onClick={() => handleClick('UI/UX & Website Development')}>Web Development</MenuLinks>
                <MenuLinks onClick={() => handleClick('Human Resource Management')}>Human Resource</MenuLinks>
              </DepartmentWrapper>
            </StyledGrid>
            <StyledGrid item xs={12} md={4}>
              <Item>
                <FooterItem title={'Contact Us'}>
                  <AddressWrapper>
                    <Box
                      sx={{
                        marginTop: '32px',
                      }}
                    >
                      <Contact>Pak: +925811555558</Contact>
                      <br />
                      <Contact>US: +19517770666</Contact>
                    </Box>
                    <Box
                      sx={{
                        marginTop: '14px',
                      }}
                    >
                      <Contact>calibreoninternationalofficial@gmail.com</Contact>
                    </Box>
                    <Box
                      sx={{
                        marginTop: '20px',
                      }}
                    >
                      <Address>Near Heli Chowk Zulfiqarabad Gilgit, 15100</Address>
                    </Box>
                    <Box
                      sx={{
                        marginTop: '20px',
                        marginBottom: '20px',
                      }}
                    >
                      <MenuLinks href="/privacy-policy">
                        <Address>Privay Policy</Address>
                      </MenuLinks>
                    </Box>
                  </AddressWrapper>
                </FooterItem>
              </Item>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </Container>
      <DesignedBy>© 2023 Calibreon International Pvt. Ltd. All Rights Reserved.</DesignedBy>
    </Wrapper>
  );
};

export default Footer;
