import {
  TestimonialDetails,
  TestimonialContentWrapper,
  TestimonialCard,
  TestimonialAuthor,
  AuthorImg,
  AuthorName,
  AuthorDetails,
  StyledVideoDiv,
} from './styles';
import imageUrlBuilder from '@sanity/image-url';
import client from '../../client';
import { getFileAsset } from '@sanity/asset-utils';

const TestimonialsCard = ({ item }) => {
  const { clientDetails, review, video } = item;
  const { clientImage, clientName, clientDesignation } = clientDetails;

  const builder = imageUrlBuilder(client);

  const getSanityFileUrl = (sanityFile) => {
    const fileUrl = getFileAsset(sanityFile, { projectId: 'hi7eel47', dataset: 'production' }).url;
    return fileUrl;
  };

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <TestimonialCard>
      <TestimonialAuthor>
        <TestimonialContentWrapper>
          {video ? (
            <StyledVideoDiv
              component="video"
              src={video && getSanityFileUrl(video)}
              height="400px"
              width="100%"
              controls
              autoPlay
              muted
            />
          ) : (
            <TestimonialDetails>{review}</TestimonialDetails>
          )}
        </TestimonialContentWrapper>
        <AuthorImg>{clientImage && <img src={urlFor(clientImage)} alt="authorImg" />}</AuthorImg>
        <AuthorName>{clientName && clientName}</AuthorName>
        <AuthorDetails>{clientDesignation && clientDesignation}</AuthorDetails>
      </TestimonialAuthor>
    </TestimonialCard>
  );
};

export default TestimonialsCard;
