import styled from '@emotion/styled';
import { Typography, Box, Input, TextareaAutosize, CircularProgress } from '@mui/material';
import Button from '../Button';
import Section from '../Section';
import { Link } from 'react-router-dom';

export const ContactWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
}));

export const ContactMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 600,
  color: theme.palette.primary?.main,
}));
export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(4),
  letterSpacing: '1.4px',
}));
export const Text = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  letterSpacing: '1px',
  marginTop: theme.spacing(3),
}));
export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 500,
  marginTop: theme.spacing(3.3),
}));

export const ContactText = styled(Typography)(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    display: 'flex',
    fontSize: '1.3rem',
    fontWeight: 100,
    color: white?.main,
    width: '70%',
  })
);

export const FormWrapper = styled(Box)({
  boxSizing: 'border-box',
});

export const InputWrapper = styled(Box)({
  display: 'block',
  width: '70%',
});

export const StyledInput = styled(Input)(({ theme }) => ({
  width: '100%',
  fontSize: '1rem',
  margin: theme.spacing(3, 0),
  height: '40px',
  '&:after': {
    display: 'none',
  },
  '&:before': {
    display: 'none',
  },
  '& input': {
    color: theme.palette.black?.light,
    padding: theme.spacing(1.7),
    paddingRight: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.secondary?.dark} !important`,
    transition: '.3s ease all',
  },
  '& input:focus': {
    borderBottom: `1px solid ${theme.palette.black?.main} !important`,
  },
}));
export const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  margin: '20px 0',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  fontSize: '16px',
  fontWeight: '500',
  transition: '.3s ease all',
  '&:focus-visible': {
    outline: 'none',
    borderBottom: `1px solid ${theme.palette.secondary?.dark} !important`,
  },
  '&:focus': {
    borderBottom: `1px solid ${theme.palette.secondary?.dark} !important`,
  },
}));

export const ContactButton = styled(Button)({
  height: '48px',
  textTransform: 'uppercase',
  transition: '.4s all',
});
export const StyledSection = styled(Section)({});
export const ContactDetails = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2.8),
}));
export const ContactItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: '0',
  marginTop: '0',
  '& svg': {
    display: 'none',
    color: theme.palette.black?.main,
    fontSize: '1rem',
  },
}));

export const IconsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingRight: theme.spacing(6),
  marginTop: theme.spacing(3),
  '& svgs': {
    marginRight: theme.spacing(3),
    color: theme.palette.black.main,
    cursor: 'pointer',
  },
}));

export const IconBorder = styled(Link)(({ theme }) => ({
  '& svg': {
    color: theme.palette.primaryDark?.main,
  },
  height: '22px',
  width: '22px',
  border: `1px solid ${theme.palette.primaryDark?.main}`,
  display: 'flex',
  justifyContent: 'center',
  outline: 'none',
  alignItems: 'center',
  padding: theme.spacing(1),
  zIndex: '0',
  cursor: 'pointer',
  borderRadius: '50%',
  marginRight: theme.spacing(1.6),
  transition: '.5s all ease',

  '&:hover': {
    transform: 'scale(1)',
    border: '1px solid transparent',
    background: theme.palette.primaryDark.main,
    svg: {
      color: theme.palette.white.main,
    },
  },
}));

export const StyledProgressContainer = styled(Box)({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
});

export const StyledCircularProgress = styled(CircularProgress)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
