import React from 'react';

import { Typography, Box } from '@mui/material';
import StyledProgress from './styles';
import {
  Label,
  CustomizeCircularProgress,
  ProgressWrap,
  CustomizeLinearProgress,
  LinearWrap,
  Percent,
  Title,
} from './styles';

export const CircularProgress = ({ title, percentage, size, thickness }) => {
  return (
    <StyledProgress>
      <ProgressWrap>
        <CustomizeCircularProgress variant="determinate" value={percentage} size={size} thickness={thickness} />
        <Percent component="span">{`${percentage}%`}</Percent>
      </ProgressWrap>
      <Title>{title}</Title>
    </StyledProgress>
  );
};

export const LinearProgress = ({ label, className, percentage }) => {
  return (
    <LinearWrap className={className}>
      <Label>{label}</Label>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <CustomizeLinearProgress variant="determinate" value={percentage} valueBuffer={percentage} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" fontSize={16} color="text.secondary">{`${Math.round(percentage)}%`}</Typography>
        </Box>
      </Box>
    </LinearWrap>
  );
};
