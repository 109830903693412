import { Box, Container } from '@mui/material'
import React from 'react'
import { MissionDesc, StyledHeading, StyledMainHeading, StyledTitle } from './styles'

const index = () => {
    return (
        <Container>
            <Box sx={{ marginTop: '70px' }}>
                <StyledMainHeading>Privacy Policy</StyledMainHeading>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <MissionDesc>Welcome to CalibreOn International ("CalibreOn," "we," "us," or "our"). This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our services ("Services"). By accessing, buying, or using our Services, you agree to the terms of this Privacy Policy.</MissionDesc>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>Information We Collect</StyledTitle>
                <StyledHeading subTitle="a) Information You Provide to Us" />
            </Box>
            <Box>
                <MissionDesc>Registration Information: When you sign up for a CalibreOn service, we collect your name, email address, home address, and phone number.

                    User Profile Information: You can create a profile to share information about yourself. This includes optional details, and for business profiles, a designated email address and payment method may be required.

                    Payment Method: We use a third-party to handle payments, and we do not store full credit card information on our servers.

                    Fulfiller Application Information: If you join our Fulfiller community, we may ask for additional information such as date of birth, address, Social Security Number, and license information. This information may be shared with partners for background checks.
                </MissionDesc>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <StyledHeading subTitle="b) Information We Collect When You Use the Services" />
            </Box>
            <Box>
                <MissionDesc>Location Information: We collect your location to match Users with nearby Fulfillers. This is crucial for the core functionality of our Services.

                    Device Information: We collect information from your device, such as IP address, device type, and mobile operating system.

                    Usage Information: We automatically receive information about your interactions with the Services to improve and analyze usage patterns.

                    Call and Text Information: For facilitated phone calls and text messages between Users and Fulfillers, we may collect details, including date, time, and content.

                    User Feedback: Ratings and reviews from Users and Fulfillers may be received.
                </MissionDesc>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <StyledHeading subTitle="c) Information We Collect from Third-Parties" />
            </Box>
            <Box>
                <MissionDesc>Third-Party Services: If you link your CalibreOn account to third-party services, we may receive information from those services.

                    Third-Party Partners: Additional information may be obtained from third-party partners and combined with other information we have about you.

                    Background Information on Fulfillers: We may perform background checks on Fulfillers through third-party partners.
                </MissionDesc>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>How We Use the Information We Collect</StyledTitle>
                <StyledHeading subTitle="a) For Users:" />
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>Connect Users with Fulfillers</MissionDesc></li>
                    <li><MissionDesc>Improve and promote the Services</MissionDesc></li>
                    <li><MissionDesc>Analyze usage patterns</MissionDesc></li>
                    <li><MissionDesc>Communicate with Users</MissionDesc></li>
                    <li><MissionDesc>Facilitate transactions and payments</MissionDesc></li>
                    <li><MissionDesc>Provide customer support</MissionDesc></li>
                    <li><MissionDesc>Prevent fraud and respond to safety issues</MissionDesc></li>
                </ul>
            </Box>


            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>How We Share the Information We Collect</StyledTitle>
                <StyledHeading subTitle="a) Sharing Between Users:" />
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>Basic information between Users and Fulfillers</MissionDesc></li>
                    <li><MissionDesc>Mutual Facebook friends may be visible during service interactions</MissionDesc></li>
                </ul>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledHeading subTitle="b) Sharing Between CalibreOn and Third-Parties:" />
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>API and integration partners</MissionDesc></li>
                    <li><MissionDesc>Third-party services</MissionDesc></li>
                    <li><MissionDesc>Service providers</MissionDesc></li>
                    <li><MissionDesc>Enterprise partners</MissionDesc></li>
                    <li><MissionDesc>International partners for global service sharing</MissionDesc></li>
                </ul>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledHeading subTitle="c) Other Sharing:" />
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>During corporate changes</MissionDesc></li>
                    <li><MissionDesc>In response to legal requests</MissionDesc></li>
                    <li><MissionDesc>With law enforcement or government authorities</MissionDesc></li>
                    <li><MissionDesc>For promotional purposes with referrers</MissionDesc></li>
                    <li><MissionDesc>With insurance partners for coverage determination</MissionDesc></li>
                    <li><MissionDesc>In aggregated or de-identified form for business partners</MissionDesc></li>
                </ul>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>How We Protect Your Information</StyledTitle>
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>Regular website security scans</MissionDesc></li>
                    <li><MissionDesc>Use of malware scanning</MissionDesc></li>
                    <li><MissionDesc>Encryption of sensitive information via SSL technology</MissionDesc></li>
                    <li><MissionDesc>Limited access to personal information</MissionDesc></li>
                    <li><MissionDesc>Transactions processed through secure gateway providers</MissionDesc></li>
                </ul>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>User Choices</StyledTitle>
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>Manage email subscriptions</MissionDesc></li>
                    <li><MissionDesc>Opt-out of push notifications</MissionDesc></li>
                    <li><MissionDesc>Control visibility of profile information</MissionDesc></li>
                    <li><MissionDesc>Manage location sharing settings</MissionDesc></li>
                    <li><MissionDesc>You can review and edit account information through account settings and profiles.</MissionDesc></li>
                </ul>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>Your Rights And Choices</StyledTitle>
            </Box>
            <Box>

                <MissionDesc>The Consumer Privacy Act grants specific rights to consumers concerning their personal information. This section elaborates on your rights and provides guidance on how to exercise them.</MissionDesc>

            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledHeading subTitle="i) Access to Specific Information and Data Portability Rights" />
                <MissionDesc>You have the right to request information about our collection and use of your personal information over the past 12 months. Upon receiving your verifiable consumer request, we will disclose:</MissionDesc>
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>The categories of personal information collected.</MissionDesc></li>
                    <li><MissionDesc>The sources of personal information.</MissionDesc></li>
                    <li><MissionDesc>Our business purpose for collecting or selling personal information.</MissionDesc></li>
                    <li><MissionDesc>Categories of third-parties with whom we share personal information.</MissionDesc></li>
                    <li><MissionDesc>Specific pieces of personal information (data portability request).</MissionDesc></li>
                    <li><MissionDesc>If personal information was sold or disclosed for a business purpose, details on sales and disclosures.</MissionDesc></li>
                </ul>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledHeading subTitle="ii) Deletion Request Rights" />
                <MissionDesc>You can request the deletion of your personal information, subject to exceptions. Upon confirming your verifiable consumer request, we will delete your information from our records unless retention is necessary for specific purposes, including:</MissionDesc>
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>Completing transactions or providing requested goods/services.</MissionDesc></li>
                    <li><MissionDesc>Detecting security incidents or illegal activities.</MissionDesc></li>
                    <li><MissionDesc>Debugging to maintain intended functionality.</MissionDesc></li>
                    <li><MissionDesc>Complying with legal obligations.</MissionDesc></li>
                    <li><MissionDesc>Supporting internal uses aligned with consumer expectations.</MissionDesc></li>
                </ul>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledHeading subTitle="iv) Response Timing and Format" />
                <MissionDesc>We aim to respond within 45 days, with extensions up to 90 days if needed. Responses cover the preceding 12 months. We deliver responses through your account or by mail/electronic means, per your preference.</MissionDesc>
                <MissionDesc>We don't charge fees unless requests are excessive, repetitive, or unfounded. If fees apply, we'll provide a cost estimate beforehand.</MissionDesc>
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>Completing transactions or providing requested goods/services.</MissionDesc></li>
                    <li><MissionDesc>Detecting security incidents or illegal activities.</MissionDesc></li>
                    <li><MissionDesc>Debugging to maintain intended functionality.</MissionDesc></li>
                    <li><MissionDesc>Complying with legal obligations.</MissionDesc></li>
                    <li><MissionDesc>Supporting internal uses aligned with consumer expectations.</MissionDesc></li>
                </ul>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledHeading subTitle="v) Non-Discrimination" />
                <MissionDesc>Exercising rights won't result in discrimination. We won't:</MissionDesc>
            </Box>
            <Box>
                <ul>
                    <li><MissionDesc>Charge different prices or rates.</MissionDesc></li>
                    <li><MissionDesc>Provide different levels/quality of goods or services.</MissionDesc></li>
                    <li><MissionDesc>Suggest different prices, rates, or quality.</MissionDesc></li>
                </ul>
            </Box>

            <Box sx={{ marginTop: '70px' }}>
                <StyledMainHeading>Privacy and Security Policy</StyledMainHeading>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>Use of Our Services and Your License</StyledTitle>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <MissionDesc>CalibreOn International's services function as a marketplace connecting individuals and businesses seeking tasks with independent assistants, receptionists, contractors, and businesses ("vendors" or "fulfillers"). Vendors may be subject to various agreements overseen by CalibreOn International. The term "Users" refers to individuals using the Application or other services seeking virtual talent or task execution.</MissionDesc>

                <MissionDesc>Subject to compliance with these Terms and payment of applicable fees, CalibreOn International grants Users and Vendors a limited, non-transferable license for personal use of the services. CalibreOn International may terminate the license without notice. Users and Vendors are prohibited from resale, derivative use, or any unauthorized extraction of data. All rights not expressly granted are retained by CalibreOn International.</MissionDesc>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>Eligibility</StyledTitle>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <MissionDesc>Users must be at least 18 years old to use the services. The services are not available to individuals under 18. By becoming a User, you confirm that you are at least 18 and have the right to enter into these Terms. Users agree to comply with applicable laws and regulations.</MissionDesc>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>Service Rules</StyledTitle>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <MissionDesc>Users agree not to engage in prohibited activities, including modifying confidential parts of the services, interfering with system integrity or security, uploading malicious software, and seeking or providing "Zero Tolerance Services."</MissionDesc>

                <MissionDesc>Non-compliance may result in service termination</MissionDesc>
            </Box>

            <Box sx={{ marginTop: '70px' }}>
                <StyledMainHeading>Payment</StyledMainHeading>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>Billing Services</StyledTitle>
            </Box>
            <Box sx={{ marginTop: '30px' }}>
                <MissionDesc>Billing Policies: Users agree to CalibreOn International's Billing Policies.
                    Authorization: Authorization may be sought for payment method verification and task cost coverage. Users are responsible for overdraft charges.
                    Fraud Prevention: Third-party payment gateways handle transactions, and CalibreOn International ensures security standards.
                    Certain services incur fees, and CalibreOn International reserves the right to adjust pricing based on request type, time, or location. Charges are non-refundable unless a demonstrable basis exists, subject to CalibreOn International's discretion.
                </MissionDesc>
            </Box>

            <Box sx={{ marginTop: '30px' }}>
                <StyledTitle>Refunds</StyledTitle>
            </Box>
            <Box sx={{ marginTop: '30px', marginBottom: '300px' }}>
                <MissionDesc>Charges are non-refundable, except at CalibreOn International's discretion.</MissionDesc>
                <MissionDesc>Agreement to Terms of Service implies agreement to CalibreOn International's Billing Policies.
                    Deactivation:
                </MissionDesc>
                <MissionDesc>For any questions or concerns regarding this Privacy Policy, please contact us at our email.</MissionDesc>
                <MissionDesc>This Privacy Policy may be updated from time to time. Please check back for any changes.</MissionDesc>
            </Box>


        </Container>


    )
}

export default index