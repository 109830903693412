import styled from '@emotion/styled';
import { Button } from '@mui/material';

const sizes = {
  sm: '34px',
  md: '44px',
  lg: '48px',
};

export const StyledButton = styled(Button)(({ size, fontSize, theme }) => ({
  padding: theme.spacing(1.5, 2),
  letterSpacing: '2px',
  borderRadius: 'unset',
  fontSize: fontSize || '1rem',
  height: sizes[size] || sizes['md'],
  [theme.breakpoints.down('md')]: {
    fontSize: '.75rem',
    padding: theme.spacing(1, 1.5),
    minWidth: 'auto',
  },
}));
