import styled from '@emotion/styled';
import { Typography, Box } from '@mui/material';
import cover from '../../assets/images/blue-background.jpg';

export const AboutHeading = styled(Typography)(({ theme }) => ({
  position: 'relative',
  fontSize: '4.375rem',
  color: theme.palette.white?.main,
  fontWeight: 'bold',
  lineHeight: 1,
  margin: theme.spacing(3, 0),
  textAlign: 'center',
  textTransform: 'capitalize',
  zIndex: '222',
  '&:before': {
    content: `' '`,
    position: 'absolute',
    display: 'block',
    top: 0,
    backgroundColor: theme.palette.white?.main,
    borderRadius: '5px 5px 5px 5px !important',
    width: '3px',
    height: '40px',
    marginTop: '-57px',
    left: '50%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
}));

export const MainBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(19),
  paddingBottom: theme.spacing(9),
  background: `url(${cover})`,
  objectFit: 'cover',
  backgroundSize: 'cover',

  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  '&:before': {
    content: `''`,
    backgroundColor: '#0c0f16',
    opacity: '.6',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: ' 0',
    left: '0',
    zIndex: '1',
  },
}));

export const Details = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  '& ol': {
    justifyContent: 'center',
  },
  '& li': {
    zIndex: '222',
    color: theme.palette.mediumGray.main,
    '& a': {
      fontSize: '1rem',
      transition: '.3s all',
      padding: theme.spacing(0, 0.5),
      '&:hover': {
        color: theme.palette.white?.main,
        textDecoration: 'none',
        fontWeight: '400',
      },
    },
  },
  '& p': {
    color: theme.palette.white?.main,
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
}));
