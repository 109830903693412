import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import OurBlogs from '../../components/OurBlogs';
import { StyledContainer } from './styles';
import MainSection from '../../components/Layout';
import sanityClient from './../../client';
import { StyledCircularProgress, StyledProgressContainer } from '../services/styles';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "blogs"]`)
      .then((data) => setBlogs(data))
      .catch(console.error);
  }, []);

  return (
    <>
      <MainSection />
      <StyledContainer>
        <Grid container spacing={4}>
          {blogs &&
            blogs?.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <OurBlogs item={item} />
              </Grid>
            ))}
          {!blogs.length && (
            <StyledProgressContainer>
              <StyledCircularProgress />
            </StyledProgressContainer>
          )}
        </Grid>
      </StyledContainer>
    </>
  );
};

export default React.memo(Blogs);
