import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

export const CustomizeCircularProgress = styled(CircularProgress)(({ size, thickness, theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:before': {
    content: '""',
    position: 'absolute',
    height: `calc(${size || 40}px - ${size / (thickness + 3) || 4 * 3}px)`,
    width: `calc(${size || 40}px - ${size / (thickness + 3) || 4 * 3}px)`,
    borderRadius: '50%',
    border: `.25rem solid ${theme?.palette?.borderColor.main}`,
    zIndex: '0',
  },
  svg: {
    zIndex: '1',
  },
}));

export const Percent = styled('span')({
  position: 'absolute',
  fontWeight: '500',
  fontSize: '1.25rem',
  letterSpacing: '0.0625rem',
});
export const CustomizeLinearProgress = styled(LinearProgress)``;
const StyledProgressBar = styled(Box)({
  textAlign: 'center',
});

export const ProgressWrap = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '.css-ahj2mt-MuiTypography-root': {
    fontSize: '1.25rem',
  },
});
export const LinearWrap = styled('div')(({ theme }) => ({
  '& p': {
    textAlign: 'left',
  },
  '&.mt-5': {
    marginTop: theme.spacing(2.7),
  },
}));

export const Label = styled('h3')(({ theme }) => ({
  textAlign: 'start',
  margin: '0',
  fontSize: '1.25rem',
  fontWeight: '400',
  lineHeight: '1.5',
  marginBottom: theme.spacing(2),
  letterSpacing: '.25rem',
}));
export const Title = styled('h2')(({ theme }) => ({
  textAlign: 'center',
  margin: '0',
  fontSize: '1.25rem',
  paddingTop: theme.spacing(2.8),
  fontWeight: '500',
  lineHeight: '1.5',
  marginBottom: theme.spacing(2),
  letterSpacing: '.25rem',
}));

export default StyledProgressBar;
