import styled from '@emotion/styled';
import { Typography, Box, Accordion, AccordionSummary } from '@mui/material';
import BgImg from '../../assets/images/blue-background.jpg';
import { Link } from 'react-router-dom';

export const UserTitle = styled(Typography)(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    fontSize: '2rem',
    color: white?.main,
    fontWeight: '500',
  })
);
export const UserRole = styled('h5')(({ theme }) => ({
  margin: '0',
  color: theme.palette.secondary.light,
  fontSize: '1.3rem',
  fontWeight: '300',
  letterSpacing: '1.4px',
}));

export const AboutDesc = styled('div')({
  letterSpacing: '1px',
  fontSize: '1rem',
  fontWeight: '400',
});

export const MainBox = styled(Box)(
  ({
    theme: {
      palette: { white, darkBlack },
      spacing,
    },
  }) => ({
    backgroundColor: white?.main,
    position: 'relative',
    paddingBottom: spacing(5),
    '& .MuiAccordion-root': {
      paddingBottom: spacing(4),
      paddingTop: spacing(1.4),
      '& .MuiAccordionSummary-root': {
        padding: 0,
      },
      '& .MuiAccordionSummary-expandIconWrapper ': {
        color: darkBlack?.main,
      },
      '& .MuiAccordionSummary-content p': {
        fontWeight: 'bold',
        fontSize: '1.25rem',
      },
    },
  })
);
export const SocialIcons = styled(Box)(
  ({
    theme: {
      palette: { white },
      spacing,
    },
  }) => ({
    position: 'absolute',
    right: '35px',
    bottom: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    zIndex: '1',

    '& svgs': {
      marginRight: spacing(4),
      color: white?.main,
      cursor: 'pointer',
    },
  })
);

export const IconBorder = styled(Link)(
  ({
    theme: {
      palette: { secondary, white, primary },
      spacing,
    },
  }) => ({
    height: '22px',
    width: '22px',
    border: `1px solid ${secondary?.dark}`,
    display: 'flex',
    justifyContent: 'center',
    outline: 'none',
    alignItems: 'center',
    margin: spacing(0.2, 1),
    padding: spacing(1),
    cursor: 'pointer',
    borderRadius: '50%',
    transition: '.5s all ease',
    '& svg ': {
      color: secondary?.dark,
      transform: 'scale(1.3)',
      padding: spacing(0.3),
      transition: '.2s all ease',
    },
    '&:hover svg': {
      color: white?.main,
      transform: 'scale(1.4)',
    },
    '&:hover': {
      border: '1px solid transparent',
      background: primary?.main,
    },
  })
);
export const StyledBox = styled(Box)(
  ({
    theme: {
      palette: { darkBlack },
      spacing,
    },
  }) => ({
    position: 'relative',
    padding: spacing(12.8, 0, 12.8, 0),
    backgroundImage: `url(${BgImg})`,
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundPosition: ' right',
    display: 'flex',
    '&:before': {
      content: `' '`,
      backgroundColor: darkBlack?.main,
      opacity: '.6',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '1',
    },
  })
);
export const DescriptionBox = styled(Box)({
  padding: '65px 0px 40px 0px',
  '.MuiBox-root': {
    marginTop: '30px',
    textAlign: 'center',
  },
});
export const UserImage = styled.img`
  height: 250px;
  width: 250px;
  border-radius: 50%;
  object-fit: cover;
  z-index: 222;
`;
export const UserContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
export const UserData = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  zIndex: '222',
}));

export const Heading = styled('h4')({
  margin: '0',
  fontSize: '1.75rem',
  fontWeight: '600',
  lineHeight: '1.5',
  letterSpacing: '2px',
  textTransform: 'capitalize',
});
export const SubHeading = styled('p')(
  ({
    theme: {
      palette: { primary },
      spacing,
    },
  }) => ({
    margin: '0',
    fontSize: '1.25rem',
    fontWeight: '300',
    letterSpacing: '2px',
    marginTop: spacing(0.8),
    marginBottom: spacing(4.6),
    textTransform: 'capitalize',
    color: primary?.main,
  })
);

export const CustomizeAccordion = styled(Accordion)(({ change, theme }) => ({
  position: 'unset',
  // border: `1px solid ${theme.palette.borderColor.main} `,
  padding: `${theme.spacing(2, 0)} !important`,
  margin: '0 !important',
  boxShadow: 'none',
  svg: {
    color: theme.palette.black.light,
    fontSize: '2.5rem',
  },
  ...(change === 'border-bottom-none' && {
    borderBottom: '1px solid transparent',
  }),
}));
export const StyledSummary = styled(AccordionSummary)(({ theme }) => ({
  margin: theme.spacing(0, 3.5),
}));

export const AccordionTitle = styled('h2')({
  fontSize: '1.25rem',
  fontWeight: '500',
  letterSpacing: '1.2px',
  margin: '0',
});

export const AccordionContent = styled('p')(({ theme }) => ({
  padding: `${theme.spacing(0, 3)} !important`,
  fontSize: '1.125rem',
  fontWeight: '300',
  letterSpacing: '1px',
  lineHeight: '1.5',
}));

export const ProgressWrapper = styled(Box)({
  textAlign: 'center',
});

export const ProgressContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
}));
export const AchivementWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
