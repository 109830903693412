import { styled } from '@mui/system';
import { Box, FormControl } from '@mui/material';

export const Control = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(2),
  width: '180px',
  background: theme.palette.primaryDark.main,
  '& .MuiInputBase-root': {
    border: 'none',
    width: '100%',
    '& fieldset': {
      border: 'none',
      padding: theme.spacing(0),
    },
    '&.Mui-focused fieldset': {
      borderWidth: theme.spacing(5),
      border: 'none',
    },
  },
  '& svg': {
    fontSize: '2rem',
    color: theme.palette.white.main,
  },
  '& .MuiSelect-icon': {
    transition: 'transform 0.2s ease-in-out',
  },
  '& svg:first-child': {
    cursor: 'pointer',
    paddingLeft: theme.spacing(1.6),
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '53px',
}));

export const DefaultValue = styled('p')(({ theme }) => ({
  margin: theme.spacing(0),
  letterSpacing: '.9px',
  fontWeight: '400',
  fontSize: '1.1rem',
}));

export const Item = styled('p')(({ theme }) => ({
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  letterSpacing: '.9px',
  fontSize: '1.1rem',
}));
