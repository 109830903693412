import { ClientReviewsContainer, CommentContainer, TestimonialContainer } from './styles';
import { AuthorDetails, AuthorName, ReviewContainer, RatedTypo } from '../testimonialsCard/styles';
import { Review, UpworkLogo } from '../../pages/banner/styles';
import Upwork from '../../assets/images/Upwork-Logo-1.png';
import { Stack } from '@mui/material';
import { Box, Rating, Typography } from '@mui/material';

const ClientReviews = ({ name, comment, authorDetails, rating }) => {
  return (
    <ClientReviewsContainer>
      <CommentContainer>
        <Typography>{comment}</Typography>
      </CommentContainer>
      <TestimonialContainer gap={2}>
        <Box>
          <AuthorName>{name}</AuthorName>
          <AuthorDetails>{authorDetails}</AuthorDetails>
        </Box>
        <ReviewContainer>
          <Review>
            <UpworkLogo>
              <img src={Upwork} alt="Upwork" />
            </UpworkLogo>
            <Stack spacing={1}>
              <Rating name="half-rating" value={rating || 4.5} precision={0.5} readOnly />
            </Stack>
            <RatedTypo>{`Rated ${rating || 4.5}/5`}</RatedTypo>
          </Review>
        </ReviewContainer>
      </TestimonialContainer>
    </ClientReviewsContainer>
  );
};

export default ClientReviews;
