import Wrapper, { SubTitle, Title } from './styles';

const Heading = ({ title, marginbottomzero, subTitle, alignleft, dark }) => {
  return (
    <Wrapper alignleft={alignleft} marginbottomzero={marginbottomzero}>
      <SubTitle dark={dark}>{subTitle}</SubTitle>
      <Title dark={dark}>{title}</Title>
    </Wrapper>
  );
};

export default Heading;
