import React from 'react';
import Button from '../Button';
import Wrapper, {
  Img,
  Information,
  TextWrapper,
  By,
  Category,
  Description,
  ButtonWrapper,
  Badge,
  BadgeWrapper,
  Date,
  Title,
} from './styles';

import imageUrlBuilder from '@sanity/image-url';
import client from '../../client';

const OurBlogs = ({ item }) => {
  const { _id, img, title, by, category, description, date } = item;

  const builder = imageUrlBuilder(client);

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <Wrapper>
      <Img>
        <img src={urlFor(img).url()} alt="Blog-A" />
      </Img>
      <TextWrapper>
        <Information>
          <By>{by}</By>
          <Category>{category}</Category>
        </Information>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <BadgeWrapper>
          <Badge>
            <Date>{date}</Date>
          </Badge>
        </BadgeWrapper>
        <ButtonWrapper to={`/blogs/${_id}`}>
          <Button>Read More</Button>
        </ButtonWrapper>
      </TextWrapper>
    </Wrapper>
  );
};

export default OurBlogs;
