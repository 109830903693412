import React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { ViewedWrapper, UserRole, DetailsWrapper, ViewedContentWrapper, StyledListItem } from './styles';
import imageUrlBuilder from '@sanity/image-url';
import client from '../../client';

const Viewed = ({ item }) => {
  const { mainHeading, users } = item;

  const builder = imageUrlBuilder(client);

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <ViewedWrapper>
      <ViewedContentWrapper>
        <UserRole>{mainHeading}</UserRole>
        <DetailsWrapper>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {users?.map((user, index) => {
              return (
                <React.Fragment key={index}>
                  <StyledListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={user.image && urlFor(user.image).url()} />
                    </ListItemAvatar>
                    <ListItemText primary={user.heading} secondary={user.subHeading} />
                  </StyledListItem>
                  {index !== users.length - 1 && <Divider variant="inset" component="li" />}
                </React.Fragment>
              );
            })}
          </List>
        </DetailsWrapper>
      </ViewedContentWrapper>
    </ViewedWrapper>
  );
};

export default Viewed;
