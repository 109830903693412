import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const NotFoundWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(20)};
`;

export const NotFoundContent = styled('div')`
  text-align: center;
`;

export const NotFoundHeader = styled('h1')`
  font-size: 96px;
  color: var(--danger);
`;

export const NotFoundSubheader = styled('h2')`
  font-size: 32px;
  color: var(--dark-charcoal);
`;

export const NotFoundText = styled('p')`
  font-size: 19px;
  color: var(--medium-gray);
`;

export const NotFoundLink = styled(Link)`
  display: inline-block;
  margin-top: ${({ theme }) => theme.spacing(8)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  background-color: var(--primary);
  color: var(--white);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--checkbox-bg);
  }
`;
