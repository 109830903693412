import { Box, InputBase } from '@mui/material';
import { styled } from '@mui/system';
import Button from '../Button';
const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'none',
  height: '56px',
  width: '100%',
  '& input': {
    height: '100%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    transition: '.4s all ease',
    boxSizing: 'border-box',
    letterSpacing: '2px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRight: 'none',
    width: '100%',
    '&:focus': {
      border: '1px solid unset',
    },
  },
}));

export const Input = styled(InputBase)(() => ({
  height: '100%',
  width: '100%',
  padding: '0',
}));
export const SearchWrapper = styled(Button)({
  display: 'flex',
  alignItems: 'center ',
  fontSize: '1.5rem',
  height: '100% !important',
  transition: '.5s all !important',
  borderRadius: 'unset',
  '& svg': {
    width: '30px',
  },
});

export default Wrapper;
