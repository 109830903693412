import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  spacing: 8,
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: '#fff',
            background: '#0097ff',
          },
        },
        {
          props: { variant: 'contained', color: 'primaryDark' },
          style: {
            color: '#fff',
            background: '#002d5e',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: '#fff',
            ':hover': {
              background: 'transparent',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: '#0097ff',
            border: '1px solid #0097ff',
            background: 'white',
          },
        },
        {
          props: { variant: 'outlined', color: 'white' },
          style: {
            color: '#fff',
            border: '1px solid #fff',
            background: 'transparent',
            ':hover': {
              background: '#fff',
              color: '#000',
              border: '1px solid #fff',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primaryDark' },
          style: {
            color: '#002d5e',
            border: '1px solid #002d5e',
            background: 'white',
            ':hover': {
              color: '#fff',
              background: '#002d5e',
              border: '1px solid transparent',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderRadius: 'unset',
            color: '#0097ff',
            border: '1px solid #0097ff',
            background: 'white',
            ':hover': {
              color: '#fff',
              background: '#0097ff',
              border: '1px solid transparent',
            },
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#0097ff',
      dark: '#002d5e',
      light: '#234a74',
    },
    secondary: {
      main: '#dedede4f',
      dark: '#98abbe',
      light: '#e0e0e0',
    },
    white: {
      main: '#fff',
      dark: '',
    },
    lightGray: {
      main: '#748494',
      100: '#363636',
    },
    darkGray: {
      main: '#00000080',
    },
    mediumGray: {
      main: '#d6d6d7',
      100: '#d5d5d5',
    },
    black: {
      main: '#000000',
      light: '#444',
    },
    lightnessWhite: {
      main: '#F6F6F6',
    },
    gray93: {
      main: '#ededed',
    },
    darkBlack: {
      main: '#0c0f16',
    },
    very_black: {
      main: '#11141b',
    },
    dark_black: {
      main: '#0c0f16',
    },
    hover_color: {
      main: '#4d4d4d',
    },
    text_light: {
      main: '#c8c8c8',
    },
    primaryDark: {
      main: '#002d5e',
    },
    borderColor: {
      main: '#c0c0c0c9',
    },
  },
});
export default theme;
