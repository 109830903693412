export const sectionVarients = {
  primary: {
    backgroundColor: '#f54c0a',
    backgroundImage: 'linear-gradient(74deg, #f54c0a 40%, #e7cf285c 100%)',
  },
  white: {
    main: '#fff',
    backgroundColor: '#fff',
    color: '#222222f0',
  },
  secondary: {
    main: '#dedede4f',
  },
  black: {
    main: '#000000',
  },
};
