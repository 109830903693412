import React from 'react';
import { StyledButton } from './styles';

const Button = ({ sx, children, fontSize, onClick, size = 'md', className, color, variant }) => {
  return (
    <StyledButton
      sx={{ sx }}
      size={size}
      onClick={onClick}
      fontSize={fontSize}
      className={className}
      color={color}
      variant={variant}
      disableRipple
    >
      {children}
    </StyledButton>
  );
};

export default Button;
