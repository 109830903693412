import React from 'react';

import imageUrlBuilder from '@sanity/image-url';
import {
  Image,
  ImageWrapper,
  TeamCardWrapper,
  UserName,
  UserRole,
  UserDetails,
  DetailsWrapper,
  TeamCardContentWrapper,
} from './styles';
import client from '../../client';

const TeamCard = ({ userImg, userName, userRole, userDetails, abbreviation }) => {
  const builder = imageUrlBuilder(client);

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <TeamCardWrapper>
      <ImageWrapper>
        <Image src={userImg && urlFor(userImg)} alt={userName} />
      </ImageWrapper>
      <TeamCardContentWrapper>
        <UserName>{userName}</UserName>
        <UserRole>
          {userRole} <br /> {abbreviation}
        </UserRole>
        <DetailsWrapper>
          <UserDetails>{userDetails}</UserDetails>
        </DetailsWrapper>
      </TeamCardContentWrapper>
    </TeamCardWrapper>
  );
};

export default TeamCard;
