import { Typography, styled } from '@mui/material';
import { Box } from '@mui/material';
import Button from '../../components/Button';

export const BannerMainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // minHeight: `calc(100vh - ${theme.spacing(40)})`,
  height: '100%',
  gap: theme.spacing(2),
  [theme?.breakpoints.down('md')]: {
    gap: theme.spacing(0),
  },
}));

export const LeftContent = styled(Box)({
  flex: '1',
});

export const StyledTypo = styled('h4')(({ theme }) => ({
  margin: '0',
  width: 'fit-content',
  marginTop: theme.spacing(10),
  fontSize: '4rem',
  color: theme.palette.black.main,
  fontWeight: '700',
  letterSpacing: '2px',
  lineHeight: '74px',
  textTransform: 'capitalize',
  [theme?.breakpoints.down('md')]: {
    fontSize: '1.6rem',
    lineHeight: '44px',
    marginTop: theme.spacing(4),
  },
  [theme?.breakpoints.down('sm')]: {
    lineHeight: '30px',
  },
}));

export const StyledContent = styled(Typography)(({ theme }) => ({
  letterSpacing: '1px',
  marginTop: theme.spacing(4),
  fontWeight: '400',
  fontSize: '1rem',
  [theme?.breakpoints.down('md')]: {
    marginTop: theme.spacing(1.2),
    fontSize: '0.875rem',
    textAlign: 'left',
    maxWidth: '80%',
    width: '100%',
  },
  [theme?.breakpoints.down('sm')]: {
    maxWidth: '100%',
    width: '100%',
    fontSize: '0.75rem',
  },
}));

export const Review = styled(Box)(({ theme }) => ({
  span: {
    fontSize: '2rem',
  },
  '& .MuiRating-iconEmpty': {
    color: theme.palette.secondary.dark,
  },
  [theme?.breakpoints.down('md')]: {
    span: {
      fontSize: '.9rem',
    },
  },
}));

export const UpworkLogo = styled(Box)(({ theme }) => ({
  width: '10rem',
  height: '4.5rem',
  img: {
    width: '100%',
  },
  [theme?.breakpoints.down('md')]: {
    width: '4.5rem',
    height: '2rem',
  },
}));

export const RatedTypo = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  marginTop: '0.25rem',
  fontSize: '1.5rem',
  [theme?.breakpoints.down('md')]: {
    fontSize: '0.8rem',
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  flex: '1',
  [theme?.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const ImageContainer = styled(Box)({
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

export const GetStartBtn = styled(Button)(({ theme }) => ({
  margin: `${theme.spacing(4)} 0`,
  fontSize: '1.125rem',
  textTransform: 'capitalize',
}));
