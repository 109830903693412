import React from 'react';
import Faqs from '../../components/FAQs/index';
import MainSection from '../../components/Layout';

import { Box } from '@mui/material';

const FAQs = () => {
  return (
    <Box>
      <MainSection />
      <Faqs />
    </Box>
  );
};

export default FAQs;
