import React, { useEffect, useState } from 'react';
import { MenuItem, IconButton, Typography, Drawer } from '@mui/material';
import { Container } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '../Button';
import { Navbar, LogoWrapper, ToolbarBox, LinksWrapper, ContactUs, Sidebar, StyledMenuIcon } from './styles';
import logoDark from './../../assets/images/Logo-dark-new.png';

const pages = [
  {
    title: 'Home',
    path: '/',
    id: 'home',
  },
  { title: 'About', path: '/about', id: 'about' },
  { title: 'Services', path: '/services', id: 'service' },
  { title: 'Blogs', path: '/blogs', id: 'blogs' },
];

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState('/');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const toggleNav = () => {
    setIsDrawerOpen(true);
  };

  const onClickLink = (path) => {
    if (window.innerWidth <= 900) {
      setIsDrawerOpen(false);
    }
    navigate(path);
    setActive(path);
  };

  const onClickContact = () => {
    navigate('/contact');
  };

  return (
    <Navbar id="header">
      <Container className="h-100">
        <ToolbarBox className="h-100">
          <StyledMenuIcon
            sx={{
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <IconButton size="large" aria-label="account of the current user" color="inherit" onClick={toggleNav}>
              <MenuIcon />
            </IconButton>
          </StyledMenuIcon>
          <LogoWrapper onClick={() => navigate('/')}>
            <img src={logoDark} alt="Dark logo" />
          </LogoWrapper>
          <LinksWrapper
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
            className="nav-links h-100 m-auto"
          >
            {pages.map((item) => {
              const isActive = item.path === active || active.includes(item.path === '/' ? null : item.path);
              return (
                <Button
                  key={`page-${item.path}`}
                  variant="text"
                  onClick={() => onClickLink(item.path)}
                  className={`btn h-100 ${isActive ? 'active' : 'nav-buttons'} ${
                    window.innerWidth <= 900 && isActive ? 'mobile-active' : ''
                  }`}
                >
                  {item.title}
                </Button>
              );
            })}
          </LinksWrapper>
          <ContactUs size="lg" variant="outlined" color="primaryDark" onClick={onClickContact}>
            Contact
          </ContactUs>
        </ToolbarBox>
      </Container>
      <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} className="mobile-drawer">
        <Sidebar>
          <LogoWrapper onClick={() => navigate('/')} className="sm-logo">
            <img src={logoDark} alt="Dark logo" />
          </LogoWrapper>
          {pages.map((item) => {
            const isActive = item.path === active || active.includes(item.path === '/' ? null : item.path);
            return (
              <MenuItem
                className={`btn h-100 ${isActive ? 'active' : 'nav-buttons'} ${
                  window.innerWidth <= 900 && isActive ? 'mobile-active' : ''
                }`}
                key={`page-${item.path}`}
                onClick={() => onClickLink(item.path)}
              >
                <Typography textAlign="center">{item.title}</Typography>
              </MenuItem>
            );
          })}
        </Sidebar>
      </Drawer>
    </Navbar>
  );
};

export default Header;
