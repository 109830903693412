import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  Button,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  ClickAwayListener,
  Container,
} from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import sanityClient from './../../client';
import imageUrlBuilder from '@sanity/image-url';

import ChipWrapper, {
  UserName,
  StyledChip,
  PostDate,
  UserProfile,
  StyledContainer,
  UserWrapper,
  MainTitle,
  UserDetail,
  ContentTitle,
  Content,
  ImageWrapper,
  AllContentWrapper,
  StyledGrid,
} from './styles';

import client from '../../client';

const SingleBlog = () => {
  const params = useParams();
  const id = params.id;
  const [blog, setBlog] = useState({});
  const { by, date, img, title, content } = blog;

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "blogs" && _id == $blogId][0]`, { blogId: id })
      .then((data) => setBlog(data))
      .catch(console.error);
  }, [id]);

  const builder = imageUrlBuilder(client);

  const urlFor = (source) => builder.image(source);

  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab' || event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Box>
      <ChipWrapper cover={content?.coverImage && urlFor(content?.coverImage).url()}>
        <StyledContainer>
          <StyledChip label="Educational" color="primary" />
          <MainTitle>{title ? title : 'Title'}</MainTitle>
        </StyledContainer>
      </ChipWrapper>
      <StyledContainer>
        <Grid container>
          <Grid item xs={10} md={10}>
            <UserWrapper>
              <UserProfile>{img && <img src={urlFor(img)} alt="profile" />}</UserProfile>
              <UserDetail>
                <UserName>{by}</UserName>
                <PostDate>{date}</PostDate>
              </UserDetail>
            </UserWrapper>
          </Grid>
          <StyledGrid item xs={2} md={2}>
            <Button
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              className="icon-button"
            >
              <MoreHoriz />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </StyledGrid>
        </Grid>
      </StyledContainer>
      <Container>
        <AllContentWrapper>
          {content?.contentData &&
            content?.contentData?.map((item, index) => {
              const { contentImage, description, heading } = item;
              return (
                <Box key={index}>
                  <ContentTitle>{heading}</ContentTitle>
                  <ImageWrapper>
                    {contentImage && <img src={urlFor(contentImage)} alt="education-Banner" />}
                  </ImageWrapper>
                  <Content>{description}</Content>
                </Box>
              );
            })}
        </AllContentWrapper>
      </Container>
    </Box>
  );
};

export default SingleBlog;
