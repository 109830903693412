import React from 'react';

function Instagram({ height }) {
  return (
    <svg
      height={height}
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather @CssClass feather-instagram"
    >
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
    </svg>
  );
}

export default Instagram;
