import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Heading from '../../components/Heading';

export const MissionDesc = styled(Typography)(({ theme }) => ({
  letterSpacing: '1px',
  fontSize: '1rem',
  color: theme.palette.black?.light,
  lineHeight: '1.5',
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export const StyledHeading = styled(Heading)(({ theme }) => ({
  letterSpacing: '1px',
  fontSize: '1rem',
  color: theme.palette.black?.light,
  lineHeight: '1.5',
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export const StyledMainHeading = styled('h1')(({ theme }) => ({
  letterSpacing: '1px',
  fontSize: '2rem',
  color: theme.palette.black?.light,
  lineHeight: '1.5',
  textAlign: 'center',
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export const StyledTitle = styled('h2')(({ theme }) => ({
  margin: '0',
  letterSpacing: '0.125rem',
  fontWeight: '600',
  lineHeight: '1.5',
  marginBottom: theme.spacing(2),
}));

export const Paragraph = styled('p')(
  ({
    theme: {
      palette: { black },
    },
  }) => ({
    boxSizing: 'border-box',
    color: black.light,
    fontSize: '1rem',
    fontWeight: '300',
    lineHeight: '2',
    padding: '0',
    letterSpacing: '1.3px',
  })
);
