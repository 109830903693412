import React from 'react';
import PrivacyComponent from '../../components/Privacy';
import MainSection from '../../components/Layout';

import { Box } from '@mui/material';

const Privacy = () => {
  return (
    <Box>
      <MainSection />
      <PrivacyComponent />
    </Box>
  );
};

export default Privacy;
